import React from 'react';
import calendarPreview from "../../../../assets/illustrations/calendar_preview.svg";
import styles from "./style.module.css"

const CalendarPreview = () => {
  return (
    <div className={styles.imageWrapper}>
      <img
        src={calendarPreview}
        alt="Calendar Preview"
        className={styles.previewImage}
      />
    </div>
  )
}

export default CalendarPreview