import React, { useState, useRef, useEffect } from 'react';
import 'primeflex/primeflex.css'; // Import PrimeFlex CSS
import { useDispatch, useSelector } from "react-redux";
import { updateCoverPage } from "../../../../reducers/webHomepageSlice";
import { casaWebConstants } from '../../../../constants/globalConstant';
import TextInput from './CustomText'; // Import the new TextInput component
import { countLimit } from '../../../../utils/globalUtils';

const WebCoverPage = ({ coverPageData, webPreviewMode }) => {
  const dispatch = useDispatch();
  const { visiblePanel } = useSelector((state) => state.webHomepage);
  const [coverPageState, setCoverPageState] = useState({
    heading: '',
    description: '',
    selectedColor: '',
    customRadio: '',
    image: '',
  });
  const [isEditable] = useState(true); // Allow editing
  const containerRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(value?.length <= countLimit.COVER_PAGE_HEADING_MAX_CHAR_ALLOWED){
      const updatedState = {
        ...coverPageState,
        [name]: value,
      };
      setCoverPageState(updatedState);
      dispatch(updateCoverPage(updatedState));
    }
  };

  useEffect(() => {
    if (coverPageData) {
      setCoverPageState((prevState) => ({
        ...prevState,
        heading: coverPageData?.heading || '',
        description: coverPageData?.description || '',
        selectedColor: coverPageData?.selectedColor || prevState.selectedColor,
        customRadio: coverPageData?.customRadio || prevState.customRadio,
        image: coverPageData?.image || prevState.image,
      }));
    }
  }, [coverPageData]);

  const headingColor = (coverPageState.selectedColor || coverPageState.customRadio) ? "#fff" : "#757575";

  return (
    <div
      ref={containerRef}
      className="p-3 border-2 border-200 border-round-lg  bg-transparent absolute"
      style={{
        border: 'none',
        borderRadius: '8px',
        backgroundColor: coverPageState.selectedColor || '#F6F6F7',
        width: webPreviewMode ? '90%' : '100%', // Adjust width based on webPreviewMode
      }}
    >
      {webPreviewMode ? (
        // Read-only layout when in preview mode
        <div >
          <div title={coverPageState.heading} style={{ fontSize: '1.7em', color: headingColor, maxHeight: "60px", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "2px",  whiteSpace: "nowrap"  }}>
            {coverPageState.heading}
          </div>
          <div title={coverPageState.description} style={{ fontSize: '1em', color: headingColor, lineHeight: '1.4', maxHeight: "60px", overflow: "hidden", textOverflow: "ellipsis" }}>
            {coverPageState.description}
          </div>
        </div>
      ) : (
        // Editable layout when not in preview mode
        <>
          <TextInput
            name="heading"
            value={coverPageState.heading}
            onChange={handleChange}
            disabled={visiblePanel === casaWebConstants.LEFT}
            isEditable={isEditable}
            placeholder='Add Heading'
            fontSize='1.6em'
            color={headingColor}
            role='add-heading'
            preventTextWrap={true}
          />
          <TextInput
            name="description"
            value={coverPageState.description}
            onChange={handleChange}
            disabled={visiblePanel === casaWebConstants.LEFT}
            isEditable={isEditable}
            placeholder='Add Description'
            fontSize='1.1em'
            color={headingColor}
            className="mb-2"
            role='add-description'
            preventTextWrap={false}
          />
        </>
      )}
    </div>
  );
};

export default WebCoverPage;
