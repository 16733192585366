import React, { useState } from "react";
import {
  globalConstants,
  menuBuilderConstants,
  ROUTES_PATH,
} from "../../../../../constants/globalConstant";
import { menuMoreOptions } from "../../../../../utils/menuBuilderUtils";
import { useDispatch } from "react-redux";
import { ReactComponent as DeleteIllustration } from "../../../../../assets/illustrations/delete_illustration.svg";
import { ReactComponent as UpdateIllustration } from "../../../../../assets/illustrations/update_illustration.svg";
import { ReactComponent as More } from "../../../../../assets/svgIcons/lsq_more.svg";
import { apiCloneWebLauncehBar, apiGetAllTeamsWebLaunchBars } from "../../../../../reducers/webLaunchbarSlice";
import { viewsMap } from "./helper";
import Modal from "../../../../../components/Modal";
import MoreOptionsPopup from "../../../../../components/MoreOptionsPopup";
import ConfirmDialog from "../../../../../components/Modal/ConfirmDialog";
import Text from "../../../../../components/Text";
import { capitalizeFirstLetter } from "../../../../../utils/globalUtils";
import useToast from "../../../../../hooks/useToast";
import { addToastMessage } from "../../../../../reducers/menuBuilderSlice";
import PublishMenuBuilder from "../../../../MenuBuilder/PublishMenuBuilder";

export const OptionTemplate = ({ view, value ,teamsCountMapping, navigate}) => {

  const toast = useToast(addToastMessage);
  const viewObj = viewsMap[view];
  const id = value[viewObj.id];

  const dispatch = useDispatch();
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const publishMenuClicked = (menu) => {
    setPublishState({
      state: true,
      data: {
        // isDefault: defaultMenuId === id,
        teamIds: teamsCountMapping[id] || [],
        menuName: menu.name,
        selectedMenuId: id,
      },
    });
  };

  const cloneLaunchbarClicked = (launcher) => {
    dispatch(apiCloneWebLauncehBar(launcher)).then(
      ({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          localStorage.setItem("currentLaunchBarInfo", JSON.stringify(payload));
          toast.success(
            `${payload?.name} was cloned successfully!`,
            "Launcher Cloned"
          );
          navigate(ROUTES_PATH.launchBarHomePage);  
        } else {
          toast.error(
            `Failed to clone ${payload?.name}. Please try again later.`,
            "Launcher Clone Failed"
          );
        }
      }
    );
    setDialog({ state: false, dialogBox: {} });
  };

  const unpublishMenuClicked = () => {
    dispatch(viewObj.apis.unpublish( id )).then(
      ({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          toast.success(
            `${payload?.menu?.name} was unpublished successfully!`,
            "Menu Unpublished"
          );
        }
        dispatch(apiGetAllTeamsWebLaunchBars())
      }
    );
    setDialog({ state: false, dialogBox: {} });
  };

  const deleteMenuClicked = () => {
    dispatch(viewObj.apis.delete(id)).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          ` was deleted successfully!`,
          `${capitalizeFirstLetter(view)} Deleted`
        );
      }
    });
    setDialog({ state: false, dialogBox: {} });
  };
  const dialogBox = (option, menu) => {
    let displayIcon = <></>;
    let actionLabel = "";
    let clickListener;
    let menuName = menu.name;
    switch (option) {
      case menuBuilderConstants.UNPUBLISH:
        {
          displayIcon = <UpdateIllustration />;
          actionLabel = menuBuilderConstants.UNPUBLISH;
          clickListener = unpublishMenuClicked;
          clickListener = unpublishMenuClicked;
        }
        break;
      case menuBuilderConstants.DELETE:
        {
          displayIcon = <DeleteIllustration />;
          actionLabel = menuBuilderConstants.DELETE;
          clickListener = deleteMenuClicked;
          clickListener = deleteMenuClicked;
        }
        break;
      default:
        break;
    }
    const ConfirmDialogHandler = (e) => {
      clickListener(menu);
      e.stopPropagation();
    };
    return (
      <ConfirmDialog
        setState={setDialog}
        illustration={displayIcon}
        buttonLabel={actionLabel}
        displayText={
          <>
            <Text type="heading" className={`capitalize`} color="#0A1F43">
              {`${actionLabel} ${menuName}`}
            </Text>
            <Text type="sub-heading" color="#0A1F43">
              {`Are you sure you want to ${actionLabel}?`}
            </Text>
          </>
        }
        clickListener={ConfirmDialogHandler}
      />
    );
  };
  const menuItemClickHandler = (label, menu) => {
    switch (label) {
      case menuBuilderConstants.CLONE:
        cloneLaunchbarClicked(menu);
        break;
      case menuBuilderConstants.DELETE:
        setDialog({
          state: true,
          dialogBox: dialogBox(menuBuilderConstants.DELETE, menu),
        });
        break;
      case menuBuilderConstants.UNPUBLISH:
        setDialog({
          state: true,
          dialogBox: dialogBox(menuBuilderConstants.UNPUBLISH, menu),
        });
        break;
      case menuBuilderConstants.PUBLISH:
        publishMenuClicked(menu);
        break;
    }
  };
  return (
    <div className={`relative`}>
      <Modal state={dialog.state}>{dialog.dialogBox}</Modal>
      <Modal state={publishState.state}>
        <PublishMenuBuilder
          setPublishState={setPublishState}
          publishState={publishState}
          view={view}
        ></PublishMenuBuilder>
      </Modal>
      <MoreOptionsPopup
        menu={value}
        options={menuMoreOptions(value)}
        onClickIcon={<More />}
        menuItemClickHandler={menuItemClickHandler}
        data-testid="menu-more-btn"
      ></MoreOptionsPopup>
    </div>
  );
};
