import React from 'react';
import styles from "./style.module.css";

const TextInput = ({ name, value, onChange, disabled, placeholder, isEditable, fontSize, fontWeight, color,className ,role, preventTextWrap=false}) => {
  return (
    disabled ? (
      <div title={value || placeholder} style={{ fontSize, color, fontWeight, maxHeight: "60px", overflow: "hidden", 
        textOverflow: "ellipsis",  whiteSpace: preventTextWrap && "nowrap" }}>
        {value || placeholder}
      </div>
    ) : (
      <input
        title={value}
        role={role}
        type="text"
        name={name}
        autoComplete='off'
        value={value}
        onChange={onChange}
        className={`w-full p-component p-mb-2 ${className} ${isEditable && styles.inputElement}`}
        placeholder={placeholder}
        style={{
          fontSize,
          fontWeight, // Apply fontWeight dynamically
          padding: '8px',         
          textAlign: 'left',
          background: 'transparent',
          color,
        }}
      />
    )
  );
};

export default TextInput;
