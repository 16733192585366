import React, { useEffect } from "react";
import Text from "../../../../components/Text";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ReactComponent as Delete } from "../../../../assets/svgIcons/lsq_delete.svg";
import { NoResultsFound } from "../../../../components/NoResultsFound";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components/CustomButton";
import { ReactComponent as NoWidgetsAdded } from "../../../../assets/illustrations/no_widgets_available.svg";
import FieldComponentsParser from "./FieldComponentsParser";
import {
  casaWebConstants,
  casaWebWidgetsConstants,
  globalConstants,
} from "../../../../constants/globalConstant";
import {
  formatMetaValueByWidgetType,
  getFormDefaultValue,
  getWidgetTitleByWidgetType,
} from "../../../../utils/casaWebUtils";
import {
  apiAddWebWidget,
  apiGetWebDashboards,
  changeSelectedWidgetLanguage,
  deleteWebWidget,
  setVisiblePanel,
  apiUpdateWebHomepage,
  updateCoverPage,
  setDeleteDialog,
  apiUpdateWebWidget,
  resetSelectedWidget,
  apiGetCloneWebDashboards,
  setIconLauncherItems,
  updateWebWidget,
} from "../../../../reducers/webHomepageSlice";
import { formatTaskMetaValue } from "../../../../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import { formatTaskTypes } from "../../../../utils/widgetUtils";
import { fetchImageURL } from "../../../../utils/leadControlsUtils";
import { formatFormData, sendAnalytics, validateCoverPage, validateThreshold } from "./helper";
import "./WidgetConfigurationRightPanel.css";
export default function WidgetConfigurationRightPanel() {
  const dispatch = useDispatch();
  const {
    selectedWidgetLanguage,
    widgetMetaData,
    renderLayoutConfig,
    selectedWebHomepage,
    entityMetaData,
    layout,
    coverPageData,
    iconLauncherItems,
  } = useSelector((state) => state.webHomepage);

  const { cloneDashboardId, id: publishedDashboardId } = selectedWebHomepage;
  const dashboardId =
    renderLayoutConfig === "clone" && cloneDashboardId
      ? cloneDashboardId
      : publishedDashboardId;

  const widgetLanguage =
    widgetMetaData[selectedWidgetLanguage?.widgetType] || {};
  const defalutValues = getFormDefaultValue(widgetLanguage);
  const defaultThresholdsValues = {
    setThresholds: false,
    min: 0,
    max: 100,
    thresholds: { min: 25, max: 75 },
  };
  const combinedDefaultValues = {
    ...defalutValues,
    ...defaultThresholdsValues,
    ...selectedWidgetLanguage?.config,
    tasksToDisplay: {},
    navigateTo: "",
    internalLink: {},
    externalLink: "",
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };
  const widgetForm = useForm({ defaultValues: combinedDefaultValues });
  useEffect(() => {
    if (selectedWidgetLanguage?.mode === globalConstants.UPDATE) {
      const fields = Object.keys(selectedWidgetLanguage?.config);
      fields.forEach((field) => {
        if (field === "image") {
          fetchImageURL(selectedWidgetLanguage?.config[field]).then(
            (payload) => {
              widgetForm.setValue(field, payload);
            }
          );
        } else {
          widgetForm.setValue(field, selectedWidgetLanguage?.config[field]);
        }
      });

      // Ensure `taskStatus` is set if it exists in the config
      if (selectedWidgetLanguage?.config?.taskStatus) {
        widgetForm.setValue(
          "taskStatus",
          selectedWidgetLanguage.config.taskStatus
        );
      }
    }
  }, [selectedWidgetLanguage]);

  useEffect(() => {
    if (selectedWidgetLanguage?.widgetType !== "Cover") return;
    const subscription = widgetForm.watch((value) => {
      if (value?.customRadio === "color" && !value?.selectedColor) {
        dispatch(
          updateCoverPage({
            ...coverPageData,
            ...value,
            selectedColor: "#000000",
          })
        );
      } else {
        dispatch(updateCoverPage({ ...coverPageData, ...value }));
      }
    });

    return () => subscription.unsubscribe();
  }, [widgetForm.watch, coverPageData]);

  const editWidgetConfig = async (formData) => {
    if (
      formData?.tasksToDisplay &&
      Object.keys(formData?.tasksToDisplay)?.length
    ) {
      delete formData.taskTypes;
      const taskTypes = formatTaskMetaValue(
        formData.tasksToDisplay,
        formatTaskTypes(entityMetaData?.tasks?.taskTypes)
      );
      delete formData.tasksToDisplay;
      formData["taskTypes"] = taskTypes;
    }

    let payload = formData;

    if (selectedWidgetLanguage.widgetType === "iconlauncher") {
      payload.launcherItems = iconLauncherItems;
    }

    if (selectedWidgetLanguage.widgetType !== "Cover") {
      await dispatch(
        apiUpdateWebWidget({
          id: selectedWidgetLanguage?.id,
          payload,
          dashboardId,
        })
      );

      dispatch(setIconLauncherItems(null));
    }

    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    dispatch(changeSelectedWidgetLanguage());
    dispatch(resetSelectedWidget());
  };

  const onSubmit = async (formData) => {
    if (!validateThreshold(widgetForm, formData)) return;
    if (!validateCoverPage(widgetForm, formData)) return;
    formData = formatFormData(formData);
    if (selectedWidgetLanguage.mode === "UPDATE") {
      let updatedLayout = layout;
      if (selectedWidgetLanguage.widgetType === "iconlauncher") {
        updatedLayout = updatedLayout.map((widget) => {
          if (widget.id === selectedWidgetLanguage?.id) {
            return {
              ...widget,
              config: {
                ...widget.config,
                launcherItems: iconLauncherItems,
              },
            };
          }
          return widget;
        });
      }
      await editWidgetConfig(formData);

      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: {
            heading: coverPageData?.heading,
            description: coverPageData?.description,
            customRadio: coverPageData?.customRadio,
            image:
              coverPageData?.customRadio === casaWebConstants.IMAGE
                ? coverPageData?.image
                : null,
            selectedColor: coverPageData?.selectedColor,
          },
        },
      };
      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      if (renderLayoutConfig === "clone" && cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
      } else {
        await dispatch(apiGetWebDashboards());
      }
      return;
    }

    const { customRadio, selectedColor, ...widgetData } = formData;

    const data = {
      ...widgetData,
    };
    customRadio;
    selectedColor;

    try {
      if (selectedWidgetLanguage.widgetType !== "Cover") {
        if (
          data?.tasksToDisplay &&
          Object.values(data?.tasksToDisplay)?.length
        ) {
          const taskTypes = formatTaskMetaValue(
            data.tasksToDisplay,
            formatTaskTypes(entityMetaData?.tasks?.taskTypes)
          );
          delete data.tasksToDisplay;
          data["taskTypes"] = taskTypes;
        }
        const postData = formatMetaValueByWidgetType(
          selectedWidgetLanguage.widgetType,
          data
        );

        let payload = postData;

        if (selectedWidgetLanguage.widgetType === "iconlauncher") {
          payload.config.launcherItems = iconLauncherItems;
        }

        const addResult = await dispatch(
          apiAddWebWidget({
            dashboardId,
            payload,
          })
        );

        sendAnalytics(payload);

        const widgetData = unwrapResult(addResult);
        const updatedLayout = layout.map((widget) =>
          widget.id === casaWebWidgetsConstants.DEMO_WIDGET
            ? { ...widget, id: widgetData.id }
            : widget
        );

        // Build the coverPage object based on the customRadio value
        const coverPage = {
          heading: coverPageData?.heading,
          description: coverPageData?.description,
          customRadio: coverPageData?.customRadio,
          image:
            coverPageData?.customRadio === casaWebConstants.IMAGE
              ? coverPageData?.image
              : null,
        };
        if (coverPageData?.customRadio === "color") {
          coverPage.selectedColor = coverPageData?.selectedColor;
        }

        const postBody = {
          layout: {
            widgets: updatedLayout,
            coverPage,
          },
        };

        await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      } else {
        const updatedLayout = layout;

        // Build the coverPage object based on the customRadio value
        const coverPage = {
          heading: coverPageData?.heading,
          description: coverPageData?.description,
          customRadio: coverPageData?.customRadio,
          image:
            coverPageData?.customRadio === casaWebConstants.IMAGE
              ? coverPageData?.image
              : null,
        };

        if (coverPageData?.customRadio === "color") {
          coverPage.selectedColor = coverPageData?.selectedColor;
        }

        const postBody = {
          layout: {
            widgets: updatedLayout,
            coverPage,
          },
        };

        await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      }

      if (renderLayoutConfig === "clone" && cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
      } else {
        await dispatch(apiGetWebDashboards());
      }

      dispatch(setVisiblePanel(casaWebConstants.LEFT));
      dispatch(changeSelectedWidgetLanguage());
      dispatch(resetSelectedWidget());
    } catch (error) {
      console.error("Failed to save widget or update homepage:", error);
    }
  };

  const discardWidgetChanges = async () => {
    dispatch(setIconLauncherItems(null));
    dispatch(deleteWebWidget({ id: casaWebWidgetsConstants.DEMO_WIDGET }));
    dispatch(changeSelectedWidgetLanguage());
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    if (selectedWidgetLanguage.widgetType === "iconlauncher") {
      const widget = layout.filter(
        (widget) => widget.id === selectedWidgetLanguage.id
      );
      dispatch(
        updateWebWidget({
          id: selectedWidgetLanguage.id,
          config: widget.config,
        })
      );
    }
    if (renderLayoutConfig === "clone" && cloneDashboardId) {
      await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
    } else {
      await dispatch(apiGetWebDashboards());
    }
  };

  const onDelete = () => {
    if (selectedWidgetLanguage.mode !== "UPDATE") discardWidgetChanges();
    else
      dispatch(
        setDeleteDialog({
          state: true,
          widgetId: selectedWidgetLanguage?.id,
        })
      );
  };
  
  return (
    <div className="widget-configuration-right-panel">
      <div className="header">
        <Text type="T1B" color="var( --text-primary)">
          {getWidgetTitleByWidgetType(selectedWidgetLanguage?.widgetType)}
        </Text>
        <div
          className="delete"
          data-testid="configuration-page-delete-btn"
          onClick={onDelete}
        >
          <Delete />
        </div>
      </div>
      <div className="configuration">
        {!widgetLanguage?.length && (
          <NoResultsFound
            Illustration={NoWidgetsAdded}
            children={
              <div>
                <Text type="T2B" color="var(--N80)">
                  {casaWebConstants.NO_WIDGETS_ADDED}
                </Text>
              </div>
            }
          />
        )}
        {widgetLanguage &&
          widgetLanguage.length > 0 &&
          widgetLanguage?.map((field) => {
            return (
              <FieldComponentsParser
                key={field.id}
                field={field}
                form={widgetForm}
                coverPageData={coverPageData}
              />
            );
          })}
      </div>
      <div className="save-cancel">
        <CustomButton
          label={casaWebConstants.CANCEL}
          varient="outline"
          onClick={discardWidgetChanges}
        />
        <CustomButton
          label={casaWebConstants.SAVE}
          varient="filled"
          onClick={widgetForm.handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}
