import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { RadioButton } from "primereact/radiobutton";
import Text from "../Text";
import { Controller } from "react-hook-form";
import "./index.css";

export default function CustomRadioGroup({
  label,
  options = [],
  control,
  radioName,
  onChange,
  optionLabelValue = false,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-2">
      <Text className="white-space-nowrap" type="T4" color="var(--N90)">
        {t(`${label}`) + " :"}
      </Text>
      <div className={`flex flex-row flex-wrap ${styles["gap-2"]}`}>
        {options.map((option) => (
          <Controller
            key={option.value}
            name={radioName}
            control={control}
            render={({ field }) => (
              <div
                className={`flex flex-row align-items-center ${styles["radio-group"]}`}
                role={radioName}
              >
                <RadioButton
                  className="lsq-radio"
                  name={radioName}
                  id={field.key}
                  value={field.value}
                  {...field}
                  {...props}
                  onChange={() => {
                    field.onChange(option.value);
                    onChange && onChange(option.value);
                  }}
                  checked={field.value === option.value}
                />
                <label htmlFor={field.key}>
                  <Text type="T4" color="#768196">
                    {optionLabelValue ? option.value : option.key}
                  </Text>
                </label>
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
}

CustomRadioGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  radioName: PropTypes.string,
  control: PropTypes.object,
};
