import React, { useCallback, useEffect, useState } from "react";
import MobileScreen from "./MobileScreen";
import { DragDropContext } from "react-beautiful-dnd";
import cloneDeep from "lodash.clonedeep";
import {
  findNumberOfWidgetsInASection,
  findRowIndexWithID,
  findSectionIndexWithId,
  getPublishedViewSections,
  onDragEndUtil,
} from "../../utils/previewPaneUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedSection,
  updatePreviewPane,
  changeToastMessage,
  apiUpdateLayout,
  setPreviewPaneDirty,
  apiDeleteSection,
  apiDiscardChanges,
  togglePreviewPaneView,
  getLayoutSections,
  clearAllToastMessages,
} from "../../reducers/previewPaneSlice";
import SectionDeleteLocator from "./SectionDeleteLocator";
import MessagingFramework from "../../components/MessagingFramework";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import useToast from "../../hooks/useToast";
import ToastMessage from "../../components/ToastMessage";
import analytics from "../../utils/analytics";
import { countLimit } from "../../utils/globalUtils";
import { ReactComponent as DraftChanges } from "../../assets/svgIcons/draft_changes.svg";
import { ReactComponent as PublishedChanges } from "../../assets/svgIcons/published_changes.svg";
import { ReactComponent as LsqFilledDelete } from "../../assets/svgIcons/lsq_delete_filled.svg";
import styles from "./styles.module.css";
import { Tooltip } from "primereact/tooltip";
import {
  casaConstants,
  globalConstants,
  toastMessageConstants,
  tooltipConstants,
} from "../../constants/globalConstant";
import MobileScreenTemplate from "../Templates/MobileScreenTemplate";
import { changePublishState } from "../../reducers/homePageSlice";

export default function PreviewPane() {
  const dispatch = useDispatch();
  const previewPaneToast = useToast(changeToastMessage);
  const mode = useSelector(
    (state) => state.widgetTemplateList.selectedWidget.mode
  );
  const previewPaneData = useSelector((state) => state.previewPane);
  const widgetListData = useSelector((state) => state.widgetTemplateList);
  const { selectedWidgetListTab } = widgetListData;
  const [showDeletePlaceholder, setShowDeletePlaceholder] = useState(false);
  const {
    toastMessages,
    hasDraftChanges,
    showCurrentChanges,
    sections,
    widgetConfiguration,
  } = previewPaneData;
  const isPreviewPaneDirty = previewPaneData.isPreviewPaneDirty;
  const showPublishedView = hasDraftChanges && !showCurrentChanges;
  const publishedViewSections = getPublishedViewSections(
    sections,
    widgetConfiguration
  );
  const homepageToastMessages = useSelector(
    (state) => state.homepage.toastMessages
  );
  const widgetTemplateListToastMessages = useSelector(
    (state) => state.widgetTemplateList.toastMessages
  );
  const templatesToastMessages = useSelector(
    (state) => state.templates.toastMessages
  );
  const previewPaneToastMessages = [
    ...toastMessages,
    ...templatesToastMessages,
    ...widgetTemplateListToastMessages,
    ...homepageToastMessages,
  ];
  const { selectedHomepageId, homepagesById } = useSelector(
    (state) => state.homepage
  );
  const homepage = homepagesById[selectedHomepageId];
  const { homepageId } = homepage || {};
  const labsData = useSelector(state => state.labs)
  const {labsPreviewSections, hasCustomFab, hasPopUpBanner} = labsData

  useUnsavedChangesWarning("", isPreviewPaneDirty);

  const debouncedFunction = (fn, timeout) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(context, args);
      }, timeout);
    };
  };

  const updateLayoutHandler = () => {
    dispatch(apiUpdateLayout());
  };

  const optimizedFn = useCallback(
    debouncedFunction(updateLayoutHandler, 10000),
    []
  );

  const deleteSectionDragValidation = (previewPane) => {
    return previewPane.sections.length !== 1;
  };

  const validateMaximumWidgetInSection = (section, previewPane) => {
    let sectionIndex;
    if (typeof section === "string") {
      sectionIndex = findSectionIndexWithId(section, previewPane);
    } else {
      sectionIndex = section;
    }

    const widgetCount = findNumberOfWidgetsInASection(
      sectionIndex,
      previewPane
    );
    return widgetCount < countLimit.WIDGET_COUNT_ACROSS_SECTION;
  };

  const fullWidgetDragValidation = (source, destination, previewPane) => {
    return (
      source.droppableId === destination.droppableId ||
      validateMaximumWidgetInSection(destination.droppableId, previewPane) !== false
    );
  };

  const halfWidgetDragValidation = (source, destination, previewPane) => {
    const sourceSectionIndex = findRowIndexWithID(
      source.droppableId,
      previewPane
    )[0];
    const destinationSectionIndex = findRowIndexWithID(
      destination.droppableId,
      previewPane
    )[0];
    if (sourceSectionIndex === destinationSectionIndex) return true;
    return validateMaximumWidgetInSection(destinationSectionIndex, previewPane) !== false;
  };

  const onDragEnd = async (result) => {
    setShowDeletePlaceholder(false);
    let clonedPreviewPane = cloneDeep(previewPaneData);
    const isTopFixedSectionPresent = sections[0]?.isFixed;
    const fixedSectionId = sections[0].sectionId;
    const source = result.source;
    const destination = result.destination;
    if (!destination) return;

    // Disabled addition/removal of widget from TOP FIXED SECTION
    if(isTopFixedSectionPresent && result.type === "widgets" && (fixedSectionId === destination.droppableId || fixedSectionId === source.droppableId)){
      return;
    }
  
    // Modified destination index if trying to move above TOP FIXED SECTION [index 0]
    if (isTopFixedSectionPresent && destination.index === 0 && result.type  === "Sections") {
      destination.index = 1;
    }
    
  
    if (destination.droppableId === "delete") {
      if (deleteSectionDragValidation(clonedPreviewPane)) {
        dispatch(apiDeleteSection(source.index))
        .then(response => {
          const { type } = response;
          if (type.includes("rejected")) 
            return;
        })
      } else {
        dispatch(
          changeToastMessage({
            type: "warning",
            title: "Warning",
            message: "You cannot delete all of the sections from the layout!",
          })
        );
        return;
      }
    }

    if (
      destination.droppableId.startsWith("section") &&
      !fullWidgetDragValidation(source, destination, clonedPreviewPane)
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: `Maximum ${countLimit.WIDGET_COUNT_ACROSS_SECTION} widgets can be added in a section`,
        })
      );
      return;
    }

    if (
      destination.droppableId.startsWith("row") &&
      !halfWidgetDragValidation(source, destination, clonedPreviewPane)
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: `Maximum ${countLimit.WIDGET_COUNT_ACROSS_SECTION} widgets can be added in a section`,
        })
      );
      return;
    }

    let newPreviewPane = onDragEndUtil(result, clonedPreviewPane);
    if (clonedPreviewPane === newPreviewPane) return;
    dispatch(setPreviewPaneDirty(true));
    dispatch(updatePreviewPane(newPreviewPane));
    const analytics_data = {
      category: "Widget",
      type: "drag_and_drop",
    };
    analytics.sendEvent("Updating_widget", analytics_data);
    optimizedFn();
  };

  const setSelectedSection = (index) => {
    dispatch(changeSelectedSection(index));
  };

  const onBeforeCapture = (instance) => {
    if (
      showDeletePlaceholder === false &&
      instance.draggableId.startsWith("section")
    ) {
      setShowDeletePlaceholder(true);
    }
  };

  const isPreviewPaneSaved = () => {
    if (isPreviewPaneDirty) {
      previewPaneToast.warn(toastMessageConstants.SAVE_UNSAVED_CHANGES);
      return false;
    }
    return true;
  };

  const onShowPublishedChangesClicked = () => {
    if (isPreviewPaneSaved()) {
      dispatch(togglePreviewPaneView(!showCurrentChanges));
    }
  };

  const onDiscardClicked = () => {
    if (isPreviewPaneSaved()) {
      dispatch(apiDiscardChanges());
    }
  };

  useEffect(() => {
    dispatch(setPreviewPaneDirty(isPreviewPaneDirty));
  }, [isPreviewPaneDirty]);

  useEffect(() => {
    dispatch(clearAllToastMessages());
    dispatch(getLayoutSections(homepageId));
    const isPublished =
      homepage?.status === casaConstants.PUBLISHED &&
      homepage?.hasDraftChanges === false;
    dispatch(changePublishState(isPublished));
  }, [homepageId]);
  return (
    <>
      <div className="flex w-full h-full relative justify-content-center gap-2">
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragEnd={onDragEnd}
        >
          {showDeletePlaceholder && mode === null && <SectionDeleteLocator />}
          {mode === null &&
          selectedWidgetListTab === globalConstants.ACTIVE_WIDGETS &&
          hasDraftChanges ? (
            <div className="flex flex-column align-items-center gap-1">
              {showCurrentChanges ? (
                <>
                  <Tooltip
                    position="left"
                    className="preview-pane-tooltip"
                    target=".current-changes"
                  />
                  <DraftChanges
                    data-pr-tooltip={tooltipConstants.PREVIEW_LAST_PUBLISHED}
                    className={`current-changes cursor-pointer ${styles["current-changes-icon"]}`}
                    onClick={onShowPublishedChangesClicked}
                  />
                </>
              ) : (
                <>
                  <Tooltip
                    position="left"
                    className="preview-pane-tooltip"
                    target=".published-changes"
                  />
                  <PublishedChanges
                    data-pr-tooltip={tooltipConstants.PREVIEW_CURRENT_CHANGES}
                    className="published-changes cursor-pointer"
                    onClick={() => {
                      dispatch(togglePreviewPaneView(!showCurrentChanges));
                    }}
                  />
                </>
              )}
              {showCurrentChanges && (
                <>
                  <Tooltip
                    position="left"
                    content={tooltipConstants.DISCARD_CHANGES}
                    className="preview-pane-tooltip"
                    target=".discard-changes"
                  />
                  <div
                    onClick={onDiscardClicked}
                    className={`discard-changes ${styles["delete-icon-container"]}`}
                  >
                    <LsqFilledDelete
                      className={styles["discard-delete-icon"]}
                      tooltip="Delete"
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div style={{ minWidth: "48px" }}></div>
          )}
          {selectedWidgetListTab === globalConstants.ACTIVE_WIDGETS &&
            (showPublishedView ? (
              <MobileScreenTemplate sections={publishedViewSections} />
            ) : (
              <MobileScreen
                previewPaneData={previewPaneData}
                setSelectedSection={setSelectedSection}
                setShowDeletePlaceholder={setShowDeletePlaceholder}
              />
            ))}
          {selectedWidgetListTab !== globalConstants.ACTIVE_WIDGETS && (
            <MobileScreenTemplate
              sections={labsPreviewSections}
              isCustomFab={hasCustomFab}
              hasPopUpBanner={hasPopUpBanner}
            />
          )}
        </DragDropContext>
        {previewPaneToastMessages.length > 0 ? (
          <div className={`${styles["toast_container"]}`}>
            <ToastMessage toastMessages={previewPaneToastMessages} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <MessagingFramework
        type={"tips"}
        message={
          "Icons may or may not show up for the users based on the access controls"
        }
      />
    </>
  );
}
