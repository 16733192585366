import { API_GET_WEB_HOMEPAGE_SIGNED_URL } from "../api/webHompageConfigurationServices";
import {
  casaConstants,
  casaWebConstants,
  casaWebWidgetsConstants,
} from "../constants/globalConstant";
import { ReactComponent as Url } from "../assets/svgIcons/url.svg"
import { ReactComponent as AddHomepage } from "../assets/svgIcons/addHomepage.svg"

export const getWidgetTitleByWidgetType = (widgetType) => {
  const widgetTitles = {
    progressbar: "Progress Bar",
    speedometer: "Speedometer",
    singlecounter: "Single Counter",
    banner: "Banner",
    calendarview: "Calendar View",
    webview: "Web View",
    iconlauncher: "Icon Launcher",
  };
  return widgetTitles[widgetType] || widgetType;
};

export function getFormDefaultValue(data) {
  let fieldNames = {};
  function traverse(node) {
    if (Array.isArray(node)) {
      node.forEach(traverse);
    } else if (typeof node === "object" && node !== null) {
      if (node.fieldName) {
        fieldNames[node?.fieldName] = node?.defaultValue || "";
      }
      Object.values(node).forEach(traverse);
    }
  }
  traverse(data);
  return fieldNames;
}

export const formatImageFile = (event) => {
  const file = event.files[0];
  const imgFile = {
    imageURL: file?.objectURL,
    name: file?.name,
  };
  event.options.clear();
  return imgFile;
};

const getEntityBasedData = (data) => {
  switch (data.entity?.id) {
    case casaWebWidgetsConstants.TASKS:
      return {
        taskStatus: data?.taskStatus,
        dateFilters: data?.dateFilters,
        taskTypes: data?.taskTypes,
      };
    case casaWebWidgetsConstants.SMART_VIEWS:
      return {
        teams: data?.teams,
        smartView: data?.smartView,
        smartViewTab: data?.smartViewTab,
      };
    default:
      return {};
  }
};

export const formatMetaValueByWidgetType = (widgetType, data) => {
  let counterWidgetData = {
    widgetType: widgetType,
    config: {
      name: data?.name?.trim(),
      icon: data?.icon,
      entity: data?.entity,
      ...getEntityBasedData(data)
    },
  };
  if(data?.setThresholds){
    counterWidgetData = {
      widgetType: widgetType,
      config: {
        name: data?.name?.trim(),
        icon: data?.icon,
        entity: data?.entity,
        ...getEntityBasedData(data),
        min:data.min,
        max:data.max,// Add min and max only if setThresholds is true
        setThresholds:true,
        thresholds:data.thresholds, // Add thresholds if present
      },
    };
  }

  const bannerWidgetData = {
    widgetType: widgetType,
    config: {
      ...data,
      image: { imageKey: data?.image?.imageKey },
    },
  };

  const widgetMetaValues = {
    progressbar: counterWidgetData,
    speedometer: counterWidgetData,
    singlecounter: counterWidgetData,
    banner: bannerWidgetData,
  };

  const defaultMetaValue = {
    widgetType: widgetType,
    config: {
      ...data
    },
  };

  return widgetMetaValues[widgetType] || defaultMetaValue;
};


export const fetchWebHomepageImageURL = async (image) => {
  let element = {
    ...image,
    uploadStatus: casaWebConstants.PENDING,
  };
  try {
    const imageKey = API_GET_WEB_HOMEPAGE_SIGNED_URL(image?.imageKey);
    const imageSignedUrlResponse = await imageKey;
    element = {
      ...image,
      imageURL: imageSignedUrlResponse.data.url,
      uploadStatus: casaWebConstants.SUCCESS,
    };
  } catch (error) {
    element = {
      ...image,
      uploadStatus: casaWebConstants.FAILED,
    };
  }
  return element;
};

// This is the dummy color code, will replace this when we will itegrate the color scheme in web homepage
export const defaultColorPaylod = {
  colourPaletId: "5aae12f4-f205-4456-ae8a-3357929c4022",
};

//This is dummy data, will fetch from API
export const webHomepages = {
  defaultDashboards: {
    teamId: "44562",
    orgCode: 44562,
    homepages: [2247, 2363],
    isActive: true,
    createdAt: "2023-01-20T00:20:42.000Z",
    updatedAt: "2024-03-13T18:06:31.000Z",
  },
  teamsDashboards: [
    {
      teamId: "6c4dab9d-eb4d-11ea-be40-0a22ff7e4b86",
      orgCode: 44562,
      homepages: [2598],
      isActive: true,
      createdAt: "2023-02-13T05:52:23.000Z",
      updatedAt: "2024-07-01T10:03:05.000Z",
    },
    {
      teamId: "f6fb0cb6-e3ad-11e9-b443-06c7f51a917e",
      orgCode: 44562,
      homepages: [2598],
      isActive: true,
      createdAt: "2023-02-13T05:52:53.000Z",
      updatedAt: "2024-07-01T10:03:05.000Z",
    },
  ],
};
//This is dummy data, will fetch from API
export const dashboardsMappedWithId = {
  2247: {
    homepageId: 2247,
    homepageName: "Task SV",
    orgCode: 44562,
    isDefault: true,
    status: "published",
  },
  2363: {
    homepageId: 2363,
    homepageName: "Updated Homepage",
    orgCode: 44562,
    isDefault: true,
    status: "published",
  },
  2598: {
    homepageId: 2598,
    homepageName: "Hompage Rename",
    orgCode: 44562,
    isDefault: true,
    status: "published",
  },
};

export const tabItemsForPublishedTemplate = [
  {
    value: "Currently Published",
    key: "published",
    id: "casweb-published-changes",
    "data-testid": "casweb-published-changes"
  },
  {
    value: "Edited",
    key: "unpublished",
    id: "casweb-unpublished-changes",
    "data-testid": "casweb-unpublished-changes"
  }
];

export const addHomepageMenuItems = [
  {
    id: casaConstants.CREATE_SIERA_HOMEPAGE,
    label: casaConstants.CREATE_SIERA_HOMEPAGE,
    Icon: <Url/>
  },
  {
    id: casaConstants.CREATE_NEW_HOMEPAGE,
    label: casaConstants.CREATE_NEW_HOMEPAGE,
    Icon: <AddHomepage/>
  }
]

export const defaultColor=["#000000","#59204C","#C93E4F",'#FC5B58','#FC8E5B',"#F5EA95"]