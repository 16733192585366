import React from "react";
import { Avatar } from "primereact/avatar";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import Text from "../../../components/Text";
import { getLeadIdentifier } from "./helper";

export default function LeadQuickViewCardMobilePreview() {
  const { selectedLeadCard, selectedLeadControl, allLeadFields } = useSelector((state) => state.leadControls);
  const identifier = getLeadIdentifier(selectedLeadControl, allLeadFields);

  return (
    <div className={`${styles["lead-list-card"]} w-full`}>
      <div className={`flex gap-1 align-items-center`}>
        <Avatar
          label={identifier.label}
          size="small"
          style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
          shape="circle"
        />
        <Text type="T1B">{identifier.name}</Text>
      </div>
      {selectedLeadCard?.isFieldsEnabledInLeadList && (
        <div className={`flex flex-column`}>
          {selectedLeadCard.visibleCardFields?.map((field) => {
            return (
              <Text type="T4" className="ellipsis" key={field.id}>
                {field.displayName}
              </Text>
            );
          })}
        </div>
      )}
      <hr className="horizontal-divider" />
      {selectedLeadCard?.isActionsEnabledInLeadList && (
        <div className={`flex flex-wrap`}>
          {selectedLeadCard.visibleCardActions?.map((action) => {
            return (
              <img
                alt={action.name}
                src={`/images/launcherIcons/${action.icon}.svg`}
                className={`w-full h-2rem p-1 ${styles["lead-actions-icon"]}`}
                style={{ flexBasis: "25%" }}
                key={action.id}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
