import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LsqExpandableTile from "../../../../components/LsqExpandableTile.js";

const DragAndDropList = ({
  fieldName,
  control,
  options,
}) => {
  const optionsMap = useMemo(() => {
    const temp = {};
    options.forEach(
      (option) => (temp[option?.schemaName] = option?.displayName)
    );
    return temp;
  }, [options]);
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value, onChange } }) => {
        const onDragEnd = (result) => {
          if (!result.destination) return;
          const reorderedItems = Array.from(value);
          const [movedItem] = reorderedItems.splice(result.source.index, 1);
          reorderedItems.splice(result.destination.index, 0, movedItem);
          onChange(reorderedItems); // Update form value after reordering
        };
        const onActionDelete = (e) => {
          onChange(value.filter((option) => option !== e));
        };

        return (
          <div className="h-full">
            <DragDropContext onDragEnd={onDragEnd}>
              <div data-testid="lead-selected-action-view">
                <Droppable droppableId="drag-list">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`flex flex-column h-full gap-1 overflow-scroll px-1 py-1`}
                    >
                      {value?.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="drag-item"
                            >
                              <LsqExpandableTile
                                showEdit={false}
                                label={optionsMap[item]}
                                onDelete={() => {
                                  onActionDelete(item);
                                }}
                                dragHandleProps={{
                                  ...provided.dragHandleProps,
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
        );
      }}
    />
  );
};

export default DragAndDropList;
