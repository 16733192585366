import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  globalConstants,
  leadControlsWidgetConstants,
  placeholderConstants,
} from "../../../constants/globalConstant";
import { useForm } from "react-hook-form";
import {
  fetchImageURL,
  formatImageFile,
  leadWidgetTileStyles,
  uploadImageInToBucket,
} from "../../../utils/leadControlsUtils";
import ControlledMultiselect from "../../../components/LSQMultiSelect/ControlledMultiselect";
import {
  addLeadWidget,
  resetLeadWidgetConfiguration,
  updateLeadWidget,
} from "../../../reducers/leadControlsSlice";
import { API_DELETE_IMAGES } from "../../../api/configurationScreenServices";
import WidgetConfigurationLayout from "./WidgetConfigurationLayout";
import {
  limitedListOptionTemplate,
  valueTemplate,
} from "../../../utils/dropdownUtils";
import DragAndDropList from "./DragDropList/DragDropList";

const AboutSettings = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const { selectedLeadControl, selectedLeadWidget, allLeadFields } =
    useSelector((state) => state.leadControls);
  const { metaData } = useSelector(
    (state) => state.leadControls.selectedWidgetConfiguration
  );
  const { widgetStyle: widgetStyles } = metaData;
  const tileStyles = leadWidgetTileStyles;

  const defaultValues = {
    identifier: "About",
    widgetStyle: "Tile",
    tileStyle: "Icon",
    leadFields: [],
  };

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const widgetStyle = watch("widgetStyle");
  const tileStyle = watch("tileStyle");

  useEffect(() => {
    !tileStyle && setValue("tileStyle", defaultValues.tileStyle);
    if (
      tileStyle === leadControlsWidgetConstants.IMAGE &&
      !(image && image?.uploadStatus === leadControlsWidgetConstants.SUCCESS)
    )
      setIsSaveDisabled(true);
    else {
      setIsSaveDisabled(false);
    }
    tileStyle === leadControlsWidgetConstants.ICON && setImage(null);
  }, [tileStyle, image]);

  useEffect(() => {
    if (selectedLeadWidget.mode === globalConstants.UPDATE) {
      const widgetDetails = selectedLeadWidget.widgetDetails.config;
      setValue("identifier", widgetDetails.identifier);
      setValue("widgetStyle", widgetDetails.widgetStyle);
      setValue("tileStyle", widgetDetails.tileStyle);
      setValue("leadFields", widgetDetails?.leadFields);
      setValue("icon", widgetDetails.icon);
      if (widgetDetails.tileStyle === leadControlsWidgetConstants.IMAGE) {
        fetchImageURL(widgetDetails.image).then((payload) => {
          setImage(payload);
        });
      }
    }
    return () => {
      reset();
    };
  }, [selectedLeadWidget]);

  const onSubmit = (data) => {
    const pageId = selectedLeadControl?.leadPage.id;
    const widgetType =
      selectedLeadWidget.mode === globalConstants.CREATE
        ? selectedLeadWidget.widgetDetails.id
        : selectedLeadWidget.widgetDetails?.config?.widgetType;
    const config = {
      identifier: data.identifier.trim(),
      widgetStyle: data.widgetStyle,
      widgetType: widgetType,
    };
    data.widgetStyle === leadControlsWidgetConstants.TILE &&
      (config["tileStyle"] = data.tileStyle);
    data.tileStyle === leadControlsWidgetConstants.IMAGE &&
      (config["image"] = {
        imageKey: image.imageKey,
      });
    config.leadFields = widgetStyle === "Detailed" ? data.leadFields : [];
    data.tileStyle === leadControlsWidgetConstants.ICON &&
      (config["icon"] = data.icon);
    if (selectedLeadWidget.mode === globalConstants.CREATE)
      dispatch(
        addLeadWidget({
          pageId: pageId,
          postbody: { config: config },
        })
      );
    else
      dispatch(
        updateLeadWidget({
          pageId: pageId,
          widgetId: selectedLeadWidget.widgetDetails.id,
          postbody: { config: config },
        })
      );
    dispatch(resetLeadWidgetConfiguration());
  };

  const imageUploadHandler = (event) => {
    const imgFile = formatImageFile(event);
    setImage({
      ...imgFile,
      uploadStatus: leadControlsWidgetConstants.PENDING,
    });
    uploadImageInToBucket(imgFile).then((response) => {
      setImage(response);
    });
  };

  const onElementDeleted = () => {
    if (selectedLeadWidget.mode === globalConstants.CREATE) {
      let key = "";
      key += String(image.imageKey);
      API_DELETE_IMAGES(key);
    }
    setImage(null);
  };

  return (
    <WidgetConfigurationLayout
      control={control}
      errors={errors}
      widgetStyles={widgetStyles}
      widgetStyle={widgetStyle}
      image={image}
      tileStyle={tileStyle}
      tileStyles={tileStyles}
      imageUploadHandler={imageUploadHandler}
      onSaveClicked={handleSubmit(onSubmit)}
      isSaveDisabled={isSaveDisabled}
      onImageDeletion={onElementDeleted}
      hasAdditionalSection={widgetStyle === "Detailed"}
      additionalSection={
        widgetStyle === "Detailed" && (
          <div className="h-full">
            <ControlledMultiselect
              label={leadControlsWidgetConstants.SELECT}
              isMandatory={true}
              errors={errors}
              optionValue={"schemaName"}
              labelValue={"displayNamex"}
              className="w-full text-base lsq-input coach-opportunity-types mb-2"
              fieldName="leadFields"
              control={control}
              options={allLeadFields}
              optionLabel="name"
              placeholder={placeholderConstants.SELECT}
              itemTemplate={(e) =>
                limitedListOptionTemplate(
                  e,
                  leadControlsWidgetConstants,
                  "displayName",
                  watch("leadFields")
                )
              }
              valueTemplate={valueTemplate}
              panelClassName="lsq-multiselect-panel lsq-select-all-panel"
              maxSelectedLabels={-1}
              id="leadFields"
              onChange={(e) => {
                if (e.value.length <= 5) setValue("leadFields", e.target.value);
              }}
            />
            <DragAndDropList
              options={allLeadFields}
              fieldName="leadFields"
              control={control}
              errors={errors}
              isMandatory={true}
              errorMessage="Please select at least one field."
            />
          </div>
        )
      }
    />
  );
};

export default AboutSettings;
