import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  menuSortItems,
} from "../../../../../utils/menuBuilderUtils";
import { menuBuilderConstants } from "../../../../../constants/globalConstant";
import { InlineDropdown } from "../../../../../components/InlineDropdown";
import ToggleTab from "../../../../../components/ToggleTab";
import {
  editTemplate,
  lastModifiedTemplate,
  statusTemplate,
  teamsTemplate,
} from "../helper/helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OptionTemplate } from "../helper/optionTemplate";
import SidePanel from "../../../../../components/SidePanel";
import TeamsAssignedView from "../../../../MenuBuilder/TeamAssignedView";
import { leadListFilterItems } from "../../../../../utils/leadControlsUtils";

const Content = ({
  activeTab,
  setActiveTab,
  data = [],
  sortState,
  defaulId = [],
  teamsCountMapping = {},
  view,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const onTabChange = (tab) => {
    setActiveTab(tab);
  };
  const onTeamCountClicked = (menuData) => {
    const menuId = menuData.id;
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaulId.length > 0 && menuId === defaulId[0],
        teamIds: teamsCountMapping[menuId] || [],
        menuName: menuData.name,
        menuId,
      },
    });
  };

  return (
    <>
      <SidePanel
        state={sidePanelState.state}
        setSidePanelState={setSidePanelState}
        style={{ top: "0" }}
      >
        <TeamsAssignedView
          setSidePanelState={setSidePanelState}
          sidePanelState={sidePanelState}
          view={view}
        />
      </SidePanel>
      <DataTable
        value={data}
        className="lsq-data-table"
        responsiveLayout="scroll"
        paginator
        rows={10}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Column
          field="name"
          style={{ width: "40%" }}
          header={
            <ToggleTab
              tabs={leadListFilterItems}
              activeTab={activeTab}
              setActiveTab={onTabChange}
              style={{ width: "70%" }}
            ></ToggleTab>
          }
          // body={menuTemplate}
        ></Column>
        <Column field="status" header="Status" body={statusTemplate}></Column>
        <Column
          field={
            activeTab.value === menuBuilderConstants.RECENTLY_CREATED
              ? "createdAt"
              : "updatedAt"
          }
          header={
            <InlineDropdown
              items={menuSortItems}
              selectedLabel={sortState[0]}
              setSelectedLabel={sortState[1]}
              style={{ alignItems: "flex-start" }}
            />
          }
          body={(e) => lastModifiedTemplate(e, sortState)}
        ></Column>
        <Column
          field={"teamsCount"}
          header="Teams"
          body={(e) =>
            teamsTemplate(e, defaulId, teamsCountMapping, onTeamCountClicked)
          }
        ></Column>
        <Column
          style={{ width: "5%" }}
          body={(e) => editTemplate(e, view, dispatch, navigate)}
        ></Column>
        <Column
          style={{ width: "5%" }}
          body={(e) => (
            <OptionTemplate
              value={e}
              view={view}
              teamsCountMapping={teamsCountMapping}
              navigate={navigate}
            />
          )}
        ></Column>
      </DataTable>
    </>
  );
};

export default Content;
