import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiGetAllTeamsWebLaunchBars,
  apiGetAllWebLaunchBars,
  apiAddWebLaunchBar,
} from "../../../../reducers/webLaunchbarSlice";
import { getTeams } from "../../../../reducers/homePageSlice";
import Header from "./Header/Header";
import Content from "./Content/Content";
import {
  menuFilterItems,
  menuSortItems,
  sortMenus,
} from "../../../../utils/menuBuilderUtils";
import { ROUTES_PATH, views } from "../../../../constants/globalConstant";
import { useNavigate } from "react-router-dom";
import { defaultColorPaylod } from "../../../../utils/casaWebUtils";
import styles from "./styles.module.css";
import { hierarchyToLinear } from "../../../../utils/globalUtils";
import { deleteTeamsData } from "../../../WebHomePages/helper/helper";
import analytics from "../../../../utils/analytics";

const LaunchBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homepageStore = useSelector((state) => state.homepage);
  const { orgCode } = useSelector((state) => state.homepage.user)||{};
  const { launchBars, teamLaunchBars } = useSelector(
    (state) => state.webLaunchBar
  );
  const searchState = useState("");
  const sortState = useState(menuSortItems[0].label);
  const [activeTab, setActiveTab] = useState(menuFilterItems[0]);

  let defaulId;
  let teamsCountMapping = {};
  Object.keys(teamLaunchBars).forEach((e) => {
    if (orgCode === e) defaulId = teamLaunchBars[e];
    else {
      teamsCountMapping[teamLaunchBars[e]] =
        teamsCountMapping[teamLaunchBars[e]] || [];
      teamsCountMapping[teamLaunchBars[e]].push(e);
    }
  });
  const navigateCreateNewDashBoard = () => {
    dispatch(apiAddWebLaunchBar({ postBody: defaultColorPaylod }))
      .then((response) => {
        const dashboardInfo = response?.payload;
        if (dashboardInfo) {
          localStorage.setItem(
            "currentLaunchBarInfo",
            JSON.stringify(dashboardInfo)
          );
          const analytics_data = {
            category: "create_new_web_launchbar",
            type: "click",
          };
          analytics.sendEvent(`create new launchbar (from navbar)`, analytics_data);
          navigate(ROUTES_PATH.launchBarHomePage);
        }
      })
      .catch((error) => {
        console.error("Error creating new dashboard:", error);
      });
  };
  const { teams: teamsHierarchy } = homepageStore;
  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  useEffect(() => {
    if (teamsHierarchy.length === 0) {
      dispatch(getTeams());
    }
    dispatch(apiGetAllWebLaunchBars());
    dispatch(apiGetAllTeamsWebLaunchBars());
  }, []);

  useEffect(async () => {
    deleteTeamsData(teamsLinear, teamLaunchBars, orgCode, dispatch, "launchBar");
  }, [teamsLinear, teamLaunchBars])

  let filteredData = launchBars.filter((launchBar) => {
    return (
      launchBar.name
        .toLowerCase()
        .includes(searchState[0].toLocaleLowerCase()) &&
      (activeTab.value === menuFilterItems[0].value ||
        launchBar.status === activeTab.value)
    );
  });
  filteredData = sortMenus(filteredData, sortState[0]);

  return (
    <div
      className="flex h-screen w-screen align-items-center p-3"
      data-testid="menu-builder-data-view"
    >
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden ml-7 
          ${styles["launchbar-tabular-view"]}`}
      >
        <Header
          searchState={searchState}
          view={views.launchBar}
          list={launchBars}
          navigateCreateNewDashBoard={navigateCreateNewDashBoard}
        />
        <Content
          data={filteredData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sortState={sortState}
          teamsCountMapping={teamsCountMapping}
          defaulId={defaulId}
          view={views.launchBar}
        />
      </div>
    </div>
  );
};

export default LaunchBar;
