export const getLeadIdentifier = (selectedLeadControl, allLeadFields=[]) => {
  // Create a map of schemaName to displayName
  const displayNameMap = allLeadFields.reduce((acc, field) => {
    acc[field.schemaName] = field.displayName;
    return acc;
  }, {});

  if (selectedLeadControl?.leadCard?.config?.title?.firstA) {
    const { firstA, firstB } = selectedLeadControl.leadCard.config.title;

    const firstADisplay = displayNameMap[firstA] || firstA;
    const firstBDisplay = displayNameMap[firstB] || firstB||'';

    return {
      name: `${firstADisplay} ${firstBDisplay}`.trim(),
      label: `${firstADisplay.charAt(0)}${firstBDisplay?.charAt(0) || ""}`,
    };
  }

  return {
    name: "",
    label: "NA",
  };
};
