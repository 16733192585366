export const beginnerSteps = [
  {
    target: ".coach-widget-panel",
    content:
      "Browse through different widgets and click “Add” to add them to your layout.",
    disableBeacon: true,
    placement: "left",
    title: "Adding Widgets!",
  },
  {
    target: ".coach-mobile-panel",
    content:
      "To add a new section to your layout, click on the “Add new section” button.",
    placement: "right",
    title: "Adding a new section",
  },
  {
    target: ".coach-top-bar",
    content:
      "Once you have added all the required widgets, click on “Publish” button to publish your homepage to the mobile app.",
    placement: "right",
    title: "Publishing a homepage!",
  },
  {
    target: "#coach-labs-tab-1",
    content: "Explore and experiment with widgets that are coming soon.",
    placement: "left",
    title: "Explore Labs!",
  },
];

export const labsSteps = [
  {
    target: "#coach-labs-preview-0",
    content: "Click on preview button to add widgets to the layout.",
    disableBeacon: true,
    placement: "bottom-end",
    title: "Preview widgets",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#coach-labs-like-0",
    content:
      "You can click on the like button for the widgets that you find interesting.",
    disableBeacon: false,
    placement: "bottom-end",
    title: "Like widgets",
    spotlightPadding: 2,
    disableScrolling: true,
  },
];

export const teamsViewSteps = [
  {
    target: ".coach-teams-column",
    content: "Take a look at all your teams in one single glance.",
    disableBeacon: true,
    placement: "right",
    title: "Teams Column",
    disableScrolling: true,
  },
  {
    target: ".coach-homepages-column",
    content:
      "Take a look at all your homepages associated to the respective teams.",
    placement: "left",
    title: "Homepages Column",
    disableScrolling: true,
  },
  {
    target: ".coach-menus-column",
    content:
      "All the menus that you have created for your teams appear here in this column.",
    placement: "left",
    title: "Menus Column",
    disableScrolling: true,
  },
];

export const sideNavigationBarSteps = [
  {
    target: "#side-menu-options",
    content:
      "Navigate between different modules like team management, home builder, menu builder or theme builder with just one click!",
    disableBeacon: true,
    placement: "right-end",
    title: "Click to select module",
    disableScrolling: true,
    spotlightPadding: 4,
  }
];

export const menuBuilderSteps = [
  {
    target: ".menu-builder-toggle-bar",
    content:
      "Toggle between side-navigation and bottom navigation editing using the switch at the top.",
    disableBeacon: true,
    placement: "bottom",
    title: "Switching between menu Types",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#bottom_navigation_available_item-add_icon0",
    content:
      "Click on the plus icon to add visible tabs. You can add up to 4 visible menu tabs to the bottom navigation.",
    placement: "left",
    title: "Adding Menu Tabs",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#bottom_navigation-darg_indicator0",
    content:
      "Drag menu tabs to rearrange them as desired using the drag handles.",
    placement: "left",
    title: "Rearranging Menu Tabs",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#create_new",
    content:
      'Click "Create New Tab" to craft a custom menu tab tailored to your needs.',
    placement: "bottom-end",
    title: "Creating Custom Menu Tabs",
    spotlightPadding: 2,
    disableScrolling: true,
  },
];

export const myOpportunitiesWidgetSteps = [
  {
    target: ".coach-opportunity-types",
    content:
      "You can now multi-select opportunity types within the dropdown option.",
    disableBeacon: true,
    placement: "bottom",
    title: "Multi-select opportunity types",
    disableScrolling: true,
    spotlightPadding: 4,
  },
  {
    target: ".coach-opportunity-status",
    content:
      "You can now arrange statuses as per your requirements by simply dragging them into appropriate sequence.",
    placement: "bottom",
    title: "Set Status Order",
    disableScrolling: true,
    spotlightPadding: 4,
  },
];

export const themeBuilderSteps = [
  {
    target: ".coach-default-theme",
    content:
      "Select any one from all the colour theming options available to you.",
    disableBeacon: true,
    placement: "left",
    title: "Select Themes",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#coach-custom-theme",
    content:
      "Don’t worry if none of the preset themes work for you, you create your own by entering colour codes as per your choice!",
    disableBeacon: true,
    placement: "left",
    title: "Custom Themes",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: ".coach-theme-preview",
    content:
      "Preview all the theming configurations in the sample previews and get a rough idea of how your app would look like before publishing the changes.",
    disableBeacon: true,
    placement: "right",
    title: "Preview",
    spotlightPadding: 2,
    disableScrolling: true,
  },
];

export const casaWebIntroSteps = [
  {
    target: "#casa_web_side_nav",
    content:
      "Homepage customization for web is now available for you. Explore now!",
    disableBeacon: true,
    placement: "right",
    title: "CASA for web is here!",
    spotlightPadding: 2,
    disableScrolling: true,
  }
]

export const casaWebDashboardConfigurationSteps = [
  {
    target: "#web-widget-left-panel",
    content:
      "Explore all the widget options from the library and click to add widgets.",
    disableBeacon: true,
    placement: "right",
    title: "Widget Library",
    spotlightPadding: 2,
    disableScrolling: true,
  },
  {
    target: "#web-cover-page-container",
    content:
      "Click to add cover images that suit your brand identity or achieve the desired look.",
    disableBeacon: true,
    placement: "right",
    title: "Cover Image",
    spotlightPadding: 2,
    disableScrolling: true,
  }
];

export const casaWebWidgetDragSteps = [
  {
    target: ".hovered",
    content:
      "Grab the drag handle to move widgets around and try resizing.",
    disableBeacon: true,
    placement: "bottom",
    title: "Widget Positioning",
    spotlightPadding: 16,
    disableScrolling: true,
  }
]