import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { homepageConstants, ROUTES_PATH } from "../constants/globalConstant";
import Teams from "../views/Teams";
import WebTeams from "../views/WebTeams";
import HomepagesView from "../views/HomepagesView";
import HomeScreen from "../views/HomeScreen";
import MenuBuilder from "../views/MenuBuilder";
import Templates from "../views/Templates";
import MenuBuilderView from "../views/MenuBuilder/MenuBuilderView";
import { sideMenuOptions, sideMenuBottomOptions } from "../utils/homepageUtils";
import LeadControlsList from "../views/LeadControls/LeadControlsList";
import ThemeBuilderMain from "../views/ThemeBuilder/main.js";
import LeadControls from "../views/LeadControls/LeadControls";
import CasaWebHomepage from "../views/CasaWeb/CasaWebHomepage/WebDashBoard/CasaWebHomepage.js";
import LaunchBar from "../views/CasaWeb/LaunchBar/LaunchbarHome/index";
import LaunchbarHomepage from "../views/CasaWeb/LaunchBar/LaunchBar";

const SideNavigationMenu = React.lazy(() =>
  import("../components/SideNavigationMenu")
);
const WebHomePages = React.lazy(() =>
  import("../views/WebHomePages/index.js")
);

function AppRoutes() {
  const { appConfig } = useSelector(state=>state.homepage);

  const featureNavigationConfig = {
    [homepageConstants.TEAMS] : appConfig.mobileHomeBuilderEnabled || appConfig.casaWebEnabled,
    [homepageConstants.MOBILE] : appConfig.mobileHomeBuilderEnabled,
    [homepageConstants.WEB] : appConfig.casaWebEnabled
  }

  let defaultPath = ROUTES_PATH.home;
  let defaultPathComponent = <Teams />

  if(!appConfig?.mobileHomeBuilderEnabled && appConfig?.casaWebEnabled) {
    defaultPath = ROUTES_PATH.webTeams;
    defaultPathComponent = <WebTeams />;
  }

  const sideNavigationOptions = sideMenuOptions(defaultPath).map((option) => {
    option.isEnabled = featureNavigationConfig[option.groupName]
    return option;
  })

  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <SideNavigationMenu
          sideMenuOptions={sideNavigationOptions}
          sideMenuBottomOptions={sideMenuBottomOptions}
        />
        <div className="main-content">
          <Routes>
            {featureNavigationConfig[homepageConstants.TEAMS] && <Route path={""} element={defaultPathComponent} />}
            {featureNavigationConfig[homepageConstants.MOBILE] && 
            (<>
              <Route path={ROUTES_PATH.homepages} element={<HomepagesView />} />
              <Route path={ROUTES_PATH.menus} element={<MenuBuilderView />} />
              <Route path={ROUTES_PATH.homebuilder} element={<HomeScreen />} />
              <Route path={ROUTES_PATH.menubuilder} element={<MenuBuilder />} />
              <Route path={ROUTES_PATH.templates} element={<Templates />} />
              <Route path={ROUTES_PATH.themebuilder} element={<ThemeBuilderMain />} />
              <Route path={ROUTES_PATH.leadControls} element={<LeadControlsList />} />
              <Route path={ROUTES_PATH.leadControlBuilder} element={<LeadControls />} />
            </>)}
            {featureNavigationConfig[homepageConstants.WEB] && 
            (<>
              <Route path={ROUTES_PATH.webDashboards} element={<WebHomePages />} />
              <Route path={ROUTES_PATH.webTeams} element={<WebTeams />} />
              <Route path={ROUTES_PATH.webHomepage} element={<CasaWebHomepage />} />
              <Route path={ROUTES_PATH.launchBar} element={<LaunchBar />} /> 
              <Route path={ROUTES_PATH.launchBarHomePage} element={<LaunchbarHomepage />} /> 
            </>)}
            <Route path="*" element={<Navigate to={{ pathname: defaultPath }} />} />
          </Routes>
        </div>
      </Suspense>
    </Router>
  );
}

export default AppRoutes;