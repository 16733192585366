import React from "react";
import styles from "./styles.module.css";
import Text from "../../../components/Text";
import { Avatar } from "primereact/avatar";
import { useSelector } from "react-redux";
import { getLeadIdentifier } from "./helper";

export default function LeadCardMobilePreview() {
  const { selectedLeadCard, selectedLeadControl, allLeadFields } = useSelector(
    (state) => state.leadControls
  );
  const isLeadIdentifierSaved = !!selectedLeadControl?.leadCard?.config?.title;
  const identifier = getLeadIdentifier(selectedLeadControl, allLeadFields);
  return (
    <div
      className="flex w-full align-items-center justify-content-center relative"
      style={{ top: "10%" }}
    >
      <div
        className={`flex flex-column w-11 relative border-round bg-white p-2`}
        style={{ minHeight: "5rem" }}
      >
        <div
          className={`flex flex-column justify-content-center relative align-items-center gap-1`}
        >
          <Avatar
            label={identifier.label}
            size="large"
            shape="circle"
            style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
            className={`${styles["lead-card-avatar"]}`}
          />
          {isLeadIdentifierSaved && (
            <>
              <Text type="T1B" className="mt-2">
                {identifier.name}
              </Text>
              <hr className="horizontal-divider" />
            </>
          )}
          <div className={`flex flex-wrap w-full `} style={{ gap: "0.2rem" }}>
            {selectedLeadCard.visibleCardFields?.map((element) => {
              return (
                <div
                  key={element.id}
                  className={`flex flex-row cursor-pointer ${
                    styles["visible-lead-field"]
                  }  ${
                    element.width === "full" && styles["expanded-lead-card"]
                  }`}
                >
                  <div className={`flex w-full relative`}>
                    <div
                      className={`flex w-full relative justify-content-start gap-3 align-items-center p-2 `}
                    >
                      {element.width === "full" ? (
                        <>
                          <Text
                            type="T4B"
                            className="capitalize"
                            color="#AFB1B6"
                          >
                            {element.type}
                          </Text>
                          <Text type="T3" className="ellipsis w-6">
                            {element.displayName}
                          </Text>
                        </>
                      ) : (
                        <div className={`flex flex-column overflow-hidden`}>
                          <Text type="T3" className="ellipsis">
                            {element.displayName}
                          </Text>
                          <Text
                            type="T4B"
                            className="capitalize"
                            color="#AFB1B6"
                          >
                            {element.type}
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="horizontal-divider" />
          <div className={`w-full ${styles["lead-actions-layout"]}`}>
            {selectedLeadCard.visibleCardActions?.map((element) => {
              return (
                <div
                  className={`flex align-items-center relative justify-content-center w-full ${styles["lead-actions-element"]}`}
                  key={element.id}
                >
                  <img
                    className={`${styles["lead-actions-icon"]}`}
                    src={`/images/launcherIcons/${element.icon}.svg`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
