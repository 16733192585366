import styles from "../styles.module.css";
import { ReactComponent as PendingSpeedometer } from "./pendingSpeedometer.svg";
import { ReactComponent as OverdueNumeric } from "./overdueNumeric.svg";
import Text from "../../components/Text";
import { widgetConstants } from "../../constants/globalConstant";

export function TaskCounterWidgetFactory({ widgetInformation }) {
  const { title, taskStatus } = widgetInformation.config;

  return (
    <div
      className={styles["widget-container"]}
      data-testid={
        taskStatus === widgetConstants.OVERDUE
          ? "overdue-task-counter"
          : "pending-task-counter"
      }
    >
      {taskStatus === widgetConstants.OVERDUE ? (
        <OverdueNumeric style={{ width: "100%", height: "100%" }} />
      ) : (
        <PendingSpeedometer style={{ width: "100%", height: "100%" }} />
      )}
      <div
        className="flex w-full absolute justify-content-center align-items-center"
        style={{ bottom: "15%" }}
      >
        <Text
        className="white-space-nowrap	overflow-ellipsis px-2"
          type={"sub-heading"}
          color={
            taskStatus === widgetConstants.OVERDUE
              ? "var(--error)"
              : "var(--N600)"
          }
          title={title}
        >
          {title}
        </Text>
      </div>
    </div>
  );
}
