import React, { useState } from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const AddCardWithOptions = ({ Custom = <></>, data, menuItemHandler, menuItems = [], containerStyle }) => {
  const [popUp, setPopUp] = useState(false);
  const menuRef = useOnClickOutside(() => setPopUp(false));
  return (
    <div ref={menuRef} style={{ position: "relative"}}>
      <div onClick={() => setPopUp(true)}>
        {Custom}
      </div>
      <div className={`${styles["container"]}`} style={{ ...containerStyle }} data-testid="add-card-menu-items">
        {popUp ? (
          <div className={`${styles["menu_container"]}`} style={{ alignItems: "flex-start" }}>
            {menuItems.map(({ label, Icon }) => {
              return (
                <div
                  className={`${styles["menu_item"]} capitalize`}
                  style={{ display: "inline-flex", alignItems: "center", gap: "0.5rem" }}
                  onClick={(e) => {
                    setPopUp(false);
                    menuItemHandler(e, label, data)
                  }}
                  key={label}
                  data-testid="add-card-menu-item"
                >
                  {Icon}
                  <Text type="T4" color="#768196">
                    {label}
                  </Text>
                </div>
              );
            })}
          </div>
        ) : (
          <> </>
        )}
      </div>
    </div>
  );
};

export default AddCardWithOptions;
