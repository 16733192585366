import { apiDeleteTeamData, apiGetAllTeamsWebDashboards } from "../../../reducers/webHomepageSlice";
import { apiGetAllTeamsWebLaunchBars } from "../../../reducers/webLaunchbarSlice";
import { deletedWebTeamsFromPlatform } from "../../../utils/globalUtils";

export const deleteTeamsData = async (linearTeams, teamsConfig, orgCode, dispatch, configType) => {
    if (linearTeams.length === 0 || Object.keys(teamsConfig).length === 0) {
        return;
    }
    const teamsMappedWithTeamId = Object.fromEntries(linearTeams.map((team) => [team.teamId, team]));
    const deletedTeamIds = deletedWebTeamsFromPlatform(
        teamsMappedWithTeamId,
        orgCode,
        teamsConfig,
    );
    if (!deletedTeamIds.length) {
        return;
    }
    const deletionPromises = deletedTeamIds.map((teamId) => 
        dispatch(apiDeleteTeamData(teamId)).unwrap()
    );
    try {  
        await Promise.all(deletionPromises);        
        // Dispatch to get updated teams/state 
        switch(configType) {
            case "launchBar":
                dispatch(apiGetAllTeamsWebLaunchBars());
                break;
            case "dashboard":
                dispatch(apiGetAllTeamsWebDashboards());
                break;
            default:
                break;
        }
    } catch (error) {  
    // Handle the error here 
    }
}