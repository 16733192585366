import {
  API_ADD_WEB_LAUNCHBAR,
  API_GET_WEB_LAUNCHBAR,
  API_GET_ALL_WEB_LAUNCHBAR,
  API_UPDATE_WEB_LAUNCHBAR,
  API_DELETE_WEB_LAUNCHBAR,
  API_PUBLISH_WEB_LAUNCHBAR,
  API_UNPUBLISH_WEB_LAUNCHBAR,
  API_UPDATE_LAUNCHBAR_TEAM_ASSIGNMENT,
  API_GET_WEB_TEAMS_LAUNCHBAR,
  API_GET_WEB_LAUNCHBAR_METADATA,
  API_CLONE_LAUNCHBAR,
  API_GET_WEB_TEAMS_LAUNCHBAR_V2,
  API_CREATE_WEB_LAUNCHBAR,
} from "../api/webLaunchBarConfigurationServices";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toastMessageConstants } from "../constants/globalConstant";

const initialState = {
  launchBars: [],
  currentLaunchBar: [],
  dashboardById: {},
  teamLaunchBars: {},
  teamLaunchBarsV2: {}, // Storing V2 API Response
  selectedLaunchBar: {},
  isLaunchBarLoading: false,
  toastMessages: [],
  publish: {
    isPublishLoading: false,
    isPublished: false,
  },
  launchBarFormMetaData: [],
};

export const apiGetAllWebLaunchBars = createAsyncThunk(
  "webLaunchBar/apiGetAllWebLaunchBars",
  async (_, thunkAPI) => {
    const response = await API_GET_ALL_WEB_LAUNCHBAR();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiGetWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiGetWebLaunchBar",
  async (dashboardId, thunkAPI) => {
    const response = await API_GET_WEB_LAUNCHBAR(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiGetAllTeamsWebLaunchBars = createAsyncThunk(
  "webLaunchBar/apiGetAllTeamsWebLaunchBars",
  async (_, thunkAPI) => {
    const response = await API_GET_WEB_TEAMS_LAUNCHBAR();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiGetAllTeamsWebLaunchBarsV2 = createAsyncThunk(
  "webLaunchBar/apiGetAllTeamsWebLaunchBarsV2",
  async (_, thunkAPI) => {
    const response = await API_GET_WEB_TEAMS_LAUNCHBAR_V2();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiCreateWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiCreateWebLaunchBar",
  async (_, thunkAPI) => {
    const response = await API_CREATE_WEB_LAUNCHBAR();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiAddWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiAddLaunchBar",
  async ({ postBody }, thunkAPI) => {
    const response = await API_ADD_WEB_LAUNCHBAR(postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiUpdateWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiUpdateWebLaunchBar",
  async (postBody, thunkAPI) => {
    const response = await API_UPDATE_WEB_LAUNCHBAR(postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const apiEditWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiEditWebLaunchBar",
  async ({ postBody, launcherID }, thunkAPI) => {
    const response = await API_UPDATE_WEB_LAUNCHBAR(postBody, launcherID);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const apiDeleteWebLaunchBar = createAsyncThunk(
  "webLaunchBar/apiDeleteWebLaunchBar",
  async (dashboardId, thunkAPI) => {
    const response = await API_DELETE_WEB_LAUNCHBAR(dashboardId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return dashboardId;
  }
);

export const publishWebLaunchBar = createAsyncThunk(
  "webLaunchBar/publishWebLaunchBar",
  async ({ menuId, postBody }, thunkAPI) => {
    const response = await API_PUBLISH_WEB_LAUNCHBAR(menuId, postBody);
    if (response?.status !== 201) {
      const errorMessage = response?.data?.errors?.[0] || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const unpublishWebLaunchBar = createAsyncThunk(
  "webLaunchBar/unpublishWebLaunchBar",
  async (dashboardId, thunkAPI) => {
    const response = await API_UNPUBLISH_WEB_LAUNCHBAR(dashboardId);
    if (response?.status !== 201) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const updateLaunchBarTeamAssignment = createAsyncThunk(
  "webLaunchBar/updateLaunchBarTeamAssignment",
  async ({ launcherID, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LAUNCHBAR_TEAM_ASSIGNMENT(
      launcherID,
      postBody
    );
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiGetWebLauncehBarMetaData = createAsyncThunk(
  "webLaunchBar/apiGetWebLauncehBarMetaData",
  async (_, thunkAPI) => {
    const response = await API_GET_WEB_LAUNCHBAR_METADATA();
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiCloneWebLauncehBar = createAsyncThunk(
  "webLaunchBar/apiCloneWebLauncehBar",
  async (launcher, thunkAPI) => {
    const response = await API_CLONE_LAUNCHBAR(launcher?.id);
    const { data } = response;
    if (response?.status !== 201) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

const webLaunchBarSlice = createSlice({
  name: "webLaunchBar",
  initialState,
  reducers: {
    addToastMessageFromWebLaunchBar(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessagesFromWebLaunchBar(state) {
      state.toastMessages.shift();
    },
    resetWebLaunchBar(state) {
      state.selectedLaunchBar = {};
      state.launchBars = [];
      state.publish = initialState.publish;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(apiGetAllWebLaunchBars.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiGetAllWebLaunchBars.fulfilled, (state, action) => {
      const launchBars = action.payload;
      const dashboardById = Object.fromEntries(
        launchBars?.map((launchBar) => {
          return [launchBar.id, launchBar];
        })
      );
      state.launchBars = launchBars;
      state.dashboardById = dashboardById;
      state.isLaunchBarLoading = false;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiGetWebLaunchBar.fulfilled, (state, action) => {
      state.currentLaunchBar = action.payload;
      state.selectedLaunchBar = action.payload;
      state.isLaunchBarLoading = false;
      state.publish.isPublished = action.payload.status;
    });
    builder.addCase(apiGetWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiGetWebLaunchBar.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiGetAllTeamsWebLaunchBars.fulfilled, (state, action) => {
      const data = action.payload;
      const teamLaunchBars = {};
      data.forEach((launchBar) => {
        const { teamId, launcherId } = launchBar;
        teamLaunchBars[teamId] = teamLaunchBars[teamId] || [];
        teamLaunchBars[teamId].push(launcherId);
      });
      state.teamLaunchBars = teamLaunchBars;
    });

    builder.addCase(apiGetAllTeamsWebLaunchBarsV2.fulfilled, (state, action) => {
      state.teamLaunchBarsV2 = action.payload;
    });

    builder.addCase(apiAddWebLaunchBar.fulfilled, (state, action) => {
      state.selectedLaunchBar = action.payload;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiAddWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiAddWebLaunchBar.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiGetWebLauncehBarMetaData.fulfilled, (state, action) => {
      state.launchBarFormMetaData = action.payload;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiGetWebLauncehBarMetaData.pending, (state, action) => {
      state.launchBarFormMetaData = action.payload;
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiGetWebLauncehBarMetaData.rejected, (state, action) => {
      state.launchBarFormMetaData = action.payload;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiUpdateWebLaunchBar.fulfilled, (state, action) => {
      state.currentLaunchBar = action.payload;
      state.selectedLaunchBar = action.payload;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiUpdateWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiUpdateWebLaunchBar.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.WEB_LAYOUT_SAVE_ERROR,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiEditWebLaunchBar.fulfilled, (state, action) => {
      state.currentLaunchBar = action.payload;
      state.selectedLaunchBar = action.payload;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiEditWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiEditWebLaunchBar.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.WEB_LAYOUT_SAVE_ERROR,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiDeleteWebLaunchBar.fulfilled, (state, action) => {
      const temp = state.launchBars.filter((e) => e.id !== action.payload);
      state.launchBars = temp;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(apiDeleteWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(apiDeleteWebLaunchBar.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLaunchBarLoading = false;
    });
    builder.addCase(publishWebLaunchBar.fulfilled, (state, action) => {
      const data = action.payload;
      const teamLaunchBars = { ...state.teamLaunchBars };
      data.forEach((launchBar) => {
        const { teamId, launcherId } = launchBar;
        teamLaunchBars[teamId] = teamLaunchBars[teamId] || [];
        teamLaunchBars[teamId].push(launcherId);
      });
      state.teamLaunchBars = teamLaunchBars;
      state.publish.isPublished = "published";
      state.publish.isPublishLoading = false;
      state.isLaunchBarLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SUCCESS,
        message: toastMessageConstants.WEB_LAYOUT_PUBLISH_SUCCESS,
      });
    });
    builder.addCase(publishWebLaunchBar.rejected, (state,action) => {
      state.publish.isPublishLoading = false;
      state.isLaunchBarLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action?.payload?.message || toastMessageConstants.WEB_LAYOUT_PUBLISH_ERROR,
      });
    });
    builder.addCase(publishWebLaunchBar.pending, (state) => {
      state.publish.isPublishLoading = true;
      state.isLaunchBarLoading = true;
    });
    builder.addCase(unpublishWebLaunchBar.fulfilled, (state, action) => {
      state.publish.isPublished = "unpublished";
      state.isLaunchBarLoading = false;

      const temp = state.launchBars.map((e) =>
        e.id == action.payload.id ? action.payload : e
      );
      state.launchBars = temp;
      state.isLaunchBarLoading = false;
    });
    builder.addCase(unpublishWebLaunchBar.pending, (state) => {
      state.isLaunchBarLoading = true;
    });
    builder.addCase(unpublishWebLaunchBar.rejected, (state) => {
      state.publish.isPublished = "published";
      state.isLaunchBarLoading = false;
    });
    builder.addCase(
      updateLaunchBarTeamAssignment.fulfilled,
      (state, action) => {
      const { addTeams, deleteTeams } = action.payload;
        const teamLaunchBars = { ...state.teamLaunchBars };
        addTeams.forEach((launchBar) => {
          const { teamId, launcherId } = launchBar;
          teamLaunchBars[teamId] = teamLaunchBars[teamId] || [];
          teamLaunchBars[teamId].push(launcherId);
        });
        deleteTeams.forEach((launchBar) => {
          const { teamId, launcherId } = launchBar;
          if (teamLaunchBars[teamId]) {
            teamLaunchBars[teamId] = teamLaunchBars[teamId].filter(
              (id) => id !== launcherId
            );
          }
        });
        state.teamLaunchBars = teamLaunchBars;
        state.isLaunchBarLoading = false;
      }
    );
  },
});

export const {
  addToastMessageFromWebLaunchBar,
  resetToastMessagesFromWebLaunchBar,
  resetWebLaunchBar,
  launchBarFormMetaData,
} = webLaunchBarSlice.actions;

export default webLaunchBarSlice.reducer;
