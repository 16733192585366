import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomImageInputV2 } from "../../../../components/CustomImageInputV2";
import { ReactComponent as Edit } from "../../../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Delete } from "../../../../assets/svgIcons/lsq_delete.svg";
import { FileUpload } from "primereact/fileupload";
import { FormErrorMessage } from "../../../../components/FormErrorMessage";
import { countLimit } from "../../../../utils/globalUtils";
import { useSelector } from "react-redux";
import { API_DELETE_IMAGES, API_GET_SIGNED_URL } from "../../../../api/configurationScreenServices";
import LsqProgressSpinner from "../../../../components/LsqProgressSpinner";
import { ImageCropper } from "./ImageCropper";
import {
  formatImageFile,
  uploadImageInToBucketWeb,
} from "../../../../utils/leadControlsUtils";
import {
  casaWebConstants,
  errorMessageConstants,
  globalConstants,
} from "../../../../constants/globalConstant";
import { updateCoverPage } from "../../../../reducers/webHomepageSlice";

export default function FileInput({ field, form, coverPageData }) {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { selectedWidgetLanguage, coverPageThumbnail } = useSelector((state) => state.webHomepage);
  const maxFileSize = countLimit.WEB_BANNER_MAX_FILE_SIZE;
  const image = coverPageData?.image || form.watch(casaWebConstants.IMAGE);
  const finalImageUrl = selectedWidgetLanguage?.widgetType === "Cover" ? coverPageThumbnail : image?.imageURL;

  const handleImageSelect = (event) => {
    const imgFile = formatImageFile(event);
    setSelectedFile(imgFile);
    setShowCropper(true);
  };

  const handleCropComplete = async (croppedImage) => {
    const imgFile = {
      ...selectedFile,
      imageURL: croppedImage
    };

    form.setValue(casaWebConstants.IMAGE, {
      ...imgFile,
      uploadStatus: casaWebConstants.PENDING,
    });

    uploadImageInToBucketWeb(imgFile, 'WIDGET').then(async (response) => {
      form.setValue(casaWebConstants.IMAGE, response);
      if(response.imageKey && response.uploadStatus === "SUCCESS"){
        const { data } = await API_GET_SIGNED_URL(response.imageKey);

        if (data?.url) {
          const updatedCoverPageData = structuredClone(coverPageData);
          updatedCoverPageData.customRadio = casaWebConstants.IMAGE;
          updatedCoverPageData.image = {...response, imageURL: data.url};
          dispatch(updateCoverPage(updatedCoverPageData));
        }
      }
    });

    setShowCropper(false);
    setSelectedFile(null);
  };

  const onElementDeleted = () => {
    if (selectedWidgetLanguage?.mode === globalConstants.CREATE) {
      let key = "";
      key += String(image.imageKey);
      API_DELETE_IMAGES(key);
    }
    form.setValue(field.fieldName, casaWebConstants.IMAGE);
    form.setValue('image', null);
  };

  if (showCropper) {
    return (
      <ImageCropper
        image={selectedFile.imageURL}
        onCancel={() => {
          setShowCropper(false);
          setSelectedFile(null);
        }}
        onCropComplete={handleCropComplete}
        setSelectedFile={setSelectedFile}
        setShowCropper={setShowCropper}
        setShowErrorMessage={setShowErrorMessage}
      />
    );
  }

  switch (image?.uploadStatus) {
    case casaWebConstants.SUCCESS:
      return (
        <div
          className="flex flex-column gap-1 w-full border-round p-2"
          style={{ background: "var(--N20)" }}
        >
          <div className="flex">
            <img
              src={finalImageUrl}
              alt="img"
              className="w-full"
              style={{
                borderRadius: "inherit",
              }}
            />
          </div>
          <div className="flex w-full justify-content-end gap-1">
            <div
              className="flex border-round cursor-pointer"
              onClick={async () => {
                setSelectedFile({ imageURL: finalImageUrl, name: "edited" });
                setShowCropper(true);
              }}
              data-testid="image-edit-btn"
            >
              <Edit style={{ width: "1.3rem" }} />
            </div>
            <div
              className="flex border-round cursor-pointer"
              onClick={() => onElementDeleted()}
              data-testid="image-delete-btn"
            >
              <Delete style={{ fill: "var(--R800)", width: "1.3rem" }} />
            </div>
            <FileUpload
              style={{ display: "none" }}
              className="w-5"
              mode="basic"
              name="banner-image"
              accept="image/jpeg,image/png,image/jpg,image/gif"
              maxFileSize={maxFileSize}
              chooseLabel="Add"
              customUpload={true}
              auto
              uploadHandler={(e) => {
                handleImageSelect(e);
                setShowErrorMessage(false);
              }}
              onValidationFail={() => setShowErrorMessage(true)}
              ref={fileRef}
            />
          </div>
          {showErrorMessage && (
            <FormErrorMessage
              message={`Please upload a file less than ${
                maxFileSize / 1000000
              }mb!`}
            />
          )}
        </div>
      );
    case casaWebConstants.PENDING:
      return <LsqProgressSpinner strokeWidth={6} strokeColor="#43536F" />;
    default:
      return (
        <div className="flex flex-column">
          <CustomImageInputV2
            imageUploadHandler={handleImageSelect}
            isMandatory={field.isMandatory || false}
            maxFileSize={maxFileSize}
            type='secondary'
          />
          {form.formState.errors[field.fieldName] && (
            <FormErrorMessage
              message={
                form.formState.errors[field.fieldName].message ||
                errorMessageConstants.MANDATORY_FIELD_ERROR
              }
            />
          )}
        </div>
      );
  }
}