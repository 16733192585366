import React from "react";
import ObjectType from "./Helpers/ObjectType";

const TaskObjectType = ({ ...props }) => {
  return (
    <ObjectType
      isSingleSelect={true}
      fieldName="objectType"
      isMandatory={false}
      showClear={true}
      {...props}
    />
  );
};

export default TaskObjectType;
