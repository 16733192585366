import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import CustomRadioGroup from "../components/CustomRadioGroup";
import TaskTypeMultiselectDropdown from "../components/TaskTypeMultiselectDropdown";
import { Skeleton } from "primereact/skeleton";
import { useDispatch } from "react-redux";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import {
  formatTaskMetaValue,
  formatTaskMetaValueToTaskFieldValue,
} from "../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import { useEffect } from "react";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { formatTaskTypes, getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import analytics from "../utils/analytics";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils"
import { stringConstants } from "../constants/globalConstant";
import TaskObjectType from "./TaskObjectType";

export function TaskListSettings() {
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId, isOpportunityEnabled } =
    useWidgetEssentials();
  const { taskType , taskStatus, associateTo, dateFilters } = selectedWidgetConfiguration.metaData
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultValues = {
    taskTitle: "My Tasks",
    tasksToDisplay: {},
    taskStatus: taskStatus?.length > 0 && taskStatus[0].value,
    associateTo : associateTo?.length > 0 && associateTo[0].value,
    dateFilters : { id : "Today", name : "Today"},
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    resetField
  } = useForm({ defaultValues });
  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    const config = {
      title: data.taskTitle.trim(),
      taskType: formatTaskMetaValue(data.tasksToDisplay, taskTypes),
      taskStatus: data.taskStatus,
      associateTo : data.associateTo,
      dateFilters : data?.dateFilters,
      objectType : data?.objectType,
    }
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'TaskList',
      'taskStatus': metaValueData.config.taskStatus,
      'associateTo' : metaValueData.config.associateTo,
    }
    if (mode === "UPDATE") {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      analytics_data["widgetId"] = selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent('Updating_TaskList', analytics_data);
    } else if (mode === "CREATE") {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent('Adding_TaskList', analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  useEffect(() => {
    if (
      mode === "UPDATE" &&
      Object.keys(selectedWidgetConfiguration.metaValue).length !== 0
    ) {
      const taskMetaFieldValue = formatTaskMetaValueToTaskFieldValue(
        selectedWidgetConfiguration.metaValue.config.taskType,
        taskTypes
      );
      setValue("taskTitle", selectedWidgetConfiguration.metaValue.config.title);
      setValue("objectType", selectedWidgetConfiguration.metaValue.config?.objectType);
      setValue(
        "taskStatus",
        selectedWidgetConfiguration.metaValue.config.taskStatus
      );
      setValue("tasksToDisplay", taskMetaFieldValue);
      setValue("associateTo", selectedWidgetConfiguration.metaValue.config.associateTo)
      setValue("dateFilters", selectedWidgetConfiguration.metaValue.config.dateFilters)
    }
  }, []);

  const taskTypes = formatTaskTypes(taskType);
  const isTaskTypesLoaded =
    taskTypes.appointments.length > 0 || taskTypes.todos.length > 0;
  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <div className={`flex flex-column gap-2`}>
            <CustomInput
              fieldName="taskTitle"
              errors={errors}
              control={control}
              isMandatory
              label="Widget Title"
              maxLength={30}
              placeholder="Type Here"
              data-testid = "task-title"
            />
          </div>
          <TaskObjectType
            control={control}
            setValue={setValue}
            errors={errors}
          />
          {taskStatus === undefined ? (
            <Skeleton type="rectangle" width="60%" height="2rem"></Skeleton>
          ) : (
            <CustomRadioGroup
              control={control}
              label="Status"
              options={taskStatus}
              radioName="taskStatus"
              onChange={() => resetField("dateFilters")}
            />
          )}
          {
          (isOpportunityEnabled && associateTo?.length > 0) &&
            <CustomRadioGroup
              control={control}
              label="Associate To"
              options={associateTo}
              radioName="associateTo"
            />
         }
         <div data-testid="taskTypes" className={`flex flex-column gap-2`}>
            {isTaskTypesLoaded ? (
              <TaskTypeMultiselectDropdown
                isMandatory={true}
                taskTypes={taskTypes}
                errors={errors}
                fieldName="tasksToDisplay"
                control={control}
              />
            ) : (
              <Skeleton type="rectangle" width="70%" height="2rem"></Skeleton>
            )}
            <CustomDropdown
              fieldName="dateFilters"
              control={control}
              errors={errors}
              className="w-full text-base lsq-input"
              options={ watch("taskStatus") === "overdue" ? dateFilters?.overdue : dateFilters?.pending }
              optionLabel="name"
              placeholder="Select"
              itemTemplate={listOptionTemplate}
              valueTemplate={valueTemplate}
              label={t(stringConstants.SELECT_DEFAULT_DATE_RANGE)}
          />
          </div>
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
        <CustomButton
          type="reset"
          onClick={() => reset()}
          varient="text"
          label="Reset"
        />
        <CustomButton type="submit" varient="filled" label="Save" />
      </div>
    </form>
  );
}
