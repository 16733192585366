import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { useFieldArray, useForm } from "react-hook-form";
import Text from "../../../components/Text";
import CustomButton from "../../../components/CustomButton";
import ToggleTab from "../../../components/ToggleTab";
import SidePanel from "../../../components/SidePanel";
import {
  formatLeadActionsVisibleFields,
  formatLeadCardVisibleFields,
  getAvailableLeadCardActions,
  getAvailableLeadCardFields,
  leadCardActions,
  leadCardFields,
  leadCardTabs,
  searchFields,
} from "../../../utils/leadControlsUtils";
import { ReactComponent as LeadCardBackground } from "../../../assets/svgIcons/lead_card_background.svg";
import { ReactComponent as Edit } from "../../../assets/svgIcons/lsq_edit.svg";
import { Avatar } from "primereact/avatar";
import LeadIdentifierForm from "./LeadIdentifierForm";
import LeadCardConfigurationPanel from "./LeadCardConfigurationPanel";
import LeadVisibleCard from "./LeadVisibleCard";
import LeadVisibleAction from "./LeadVisibleAction";
import {
  globalConstants,
  leadControlsConstants,
} from "../../../constants/globalConstant";
import { DragDropContext } from "react-beautiful-dnd";
import {
  getLeadCardMetaData,
  getLeadFields,
  setLeadConfigurationDirty,
  updateLeadCard,
} from "../../../reducers/leadControlsSlice";
import { getLeadIdentifier } from "./helper";

const LeadCard = () => {
  const dispatch = useDispatch();
  const { leadCard, selectedLeadControl, selectedLeadCard, allLeadFields } =
    useSelector((state) => state.leadControls);
  const [toggleLeadIdentifier, setToggleLeadIdentifier] = useState(false);
  const [showCardPlaceholder, setShowCardPlaceHolder] = useState(false);
  const [isLeadIdentifierSaved, setIsLeadIdentifierSaved] = useState(false);
  const cardTabs = leadCardTabs(isLeadIdentifierSaved);
  const [leadCardActiveTab, setLeadCardActiveTab] = useState(cardTabs[0]);
  const searchState = useState("");
  const defaultValues = {
    visibleCardFields: leadCardFields,
    availableCardFields: leadCard.leadFields,
    visibleCardActions: leadCardActions,
    availableCardActions: leadCard.leadActions,
    leadCardFieldsEnabledInLeadList: true,
    leadCardActionsEnabledInLeadList: true,
  };
  const { control, watch, setValue, reset } = useForm({
    defaultValues: defaultValues,
  });
  const isFieldsEnabledInLeadList = watch("leadCardFieldsEnabledInLeadList");
  const isActionsEnabledInLeadList = watch("leadCardActionsEnabledInLeadList");

  useEffect(() => {
    if (allLeadFields.length === 0) {
      dispatch(getLeadFields());
    }
    if (leadCard.leadActions.length === 0) {
      dispatch(getLeadCardMetaData());
    }
  }, []);

  useEffect(() => {
    setIsLeadIdentifierSaved(!!selectedLeadControl?.leadCard?.config?.title);
    if (Object.keys(selectedLeadCard).length === 0) {
      reset({
        leadCardFieldsEnabledInLeadList: true,
        leadCardActionsEnabledInLeadList: true,
        visibleCardActions: leadCardFields,
        visibleCardFields: leadCardActions,
      });
    }
  }, [selectedLeadControl]);

  const visibleCardFields = useFieldArray({
    control,
    name: "visibleCardFields",
    keyName: "key",
  });
  const visibleCardActions = useFieldArray({
    control,
    name: "visibleCardActions",
    keyName: "key",
  });
  const availableCardFields = useFieldArray({
    control,
    name: "availableCardFields",
    keyName: "key",
  });
  const availableCardActions = useFieldArray({
    control,
    name: "availableCardActions",
    keyName: "key",
  });

  useEffect(() => {
    if (selectedLeadControl?.leadCard?.config?.title) {
      const selectedCard = selectedLeadControl?.leadCard?.config;
      setValue(
        "leadCardFieldsEnabledInLeadList",
        selectedCard.useLeadDetailsInListCard
      );
      setValue(
        "leadCardActionsEnabledInLeadList",
        selectedCard.useActionsInListCard
      );
      visibleCardFields.replace(
        formatLeadCardVisibleFields(
          leadCardFields,
          selectedCard?.leadDetails,
          leadCard.leadFields
        )
      );
      visibleCardActions.replace(
        formatLeadActionsVisibleFields(
          leadCardActions,
          selectedCard?.actions,
          leadCard.leadActions
        )
      );
      availableCardFields.replace(
        getAvailableLeadCardFields(
          leadCard?.leadFields,
          selectedCard?.leadDetails
        )
      );
      availableCardActions.replace(
        getAvailableLeadCardActions(
          leadCard?.leadActions,
          selectedCard?.actions
        )
      );
    } else {
      availableCardFields.replace(leadCard.leadFields);
      availableCardActions.replace(leadCard.leadActions);
    }
  }, [leadCard, selectedLeadControl]);

  const onSubmitLeadIdentifierForm = (data) => {
    setToggleLeadIdentifier(false);
    dispatch(
      updateLeadCard({
        leadControlId: selectedLeadControl?.id,
        cardId: selectedLeadControl?.leadCard.id,
        postBody: {
          config: {
            title: {
              firstA: data.firstA?.schemaName || "",
              firstB: data.firstB?.schemaName || "",
              second: data.second?.schemaName || "",
              third: data.third?.schemaName || "",
              fourth: data.fourth || "",
            },
            leadDetails: selectedLeadCard?.visibleCardFields || [],
            actions: selectedLeadCard?.visibleCardActions || [],
            useLeadDetailsInListCard: isFieldsEnabledInLeadList,
            useActionsInListCard: isActionsEnabledInLeadList,
          },
        },
      })
    ).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED))
        setIsLeadIdentifierSaved(true);
    });
  };

  const onBeforeCapture = (instance) => {
    if (
      showCardPlaceholder === false &&
      instance.draggableId.startsWith("available-lead-cards")
    ) {
      setShowCardPlaceHolder(true);
    }
  };
  const onDragEnd = (result) => {
    setShowCardPlaceHolder(false);
    const source = result.source;
    const destination = result.destination;
    if (!destination || destination.droppableId === "lead-available-card")
      return;
    if (result.type === "lead-visible-fields") {
      visibleCardFields.move(result.source.index, result.destination.index);
    }
    if (result.type === "lead-available-cards") {
      const { schemaName } = searchedAvailableFields[source.index];
      const selectedIndex = availableCardFields.fields.findIndex(
        (element) => element.schemaName === schemaName
      );
      visibleCardFields.update(parseInt(destination.droppableId), {
        ...visibleCardFields.fields[parseInt(destination.droppableId)],
        view: leadControlsConstants.FILLED,
        ...searchedAvailableFields[source.index],
      });
      availableCardFields.remove(selectedIndex);
    }
    dispatch(setLeadConfigurationDirty(true));
  };

  let searchedAvailableFields = searchFields(
    availableCardFields.fields,
    searchState[0]
  );
  const identifier = getLeadIdentifier(selectedLeadControl, allLeadFields);
  return (
    <div
      className={`flex w-full h-full flex-column overflow-scroll align-items-center gap-2`}
      data-testid="lead-card-view"
    >
      <div className={`flex w-25rem p-1 border-round mt-3 bg-white`}>
        <ToggleTab
          tabs={leadCardTabs(!isLeadIdentifierSaved)}
          activeTab={leadCardActiveTab}
          setActiveTab={setLeadCardActiveTab}
          textType={"T4B"}
        />
      </div>
      {leadCardActiveTab.value === leadControlsConstants.QUICK_VIEW_CARD ? (
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result)}
          onBeforeCapture={onBeforeCapture}
        >
          <div className="flex flex-column relative align-items-center">
            <LeadCardBackground />
            <div
              className={`flex flex-column absolute w-25rem border-round bg-white p-3 top-50`}
            >
              <div
                className={`flex flex-column justify-content-center align-items-center relative gap-2`}
              >
                <Avatar
                  label={identifier.label}
                  size="large"
                  shape="circle"
                  style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                  className={`${styles["lead-card-avatar"]}`}
                />
                {isLeadIdentifierSaved ? (
                  <div className="flex gap-1 mt-3 overflow-hidden w-full justify-content-center">
                    <Text
                      type="H2B"
                      className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                      title={identifier?.name}
                    >
                      {identifier?.name}
                    </Text>
                    <Edit
                      className="cursor-pointer flex-shrink-0"
                      onClick={() => setToggleLeadIdentifier(true)}
                    />
                  </div>
                ) : (
                  <CustomButton
                    label={leadControlsConstants.CLICK_TO_ADD_FIELDS}
                    varient="outline"
                    style={{ width: "100%", marginTop: "1.5rem" }}
                    onClick={() => setToggleLeadIdentifier(true)}
                    data-testid="add-field-btn"
                  ></CustomButton>
                )}
                <hr className="horizontal-divider" />
                <LeadVisibleCard
                  visibleCardFields={visibleCardFields}
                  availableCardFields={availableCardFields}
                  showCardPlaceholder={showCardPlaceholder}
                />
                <hr className="horizontal-divider" />
                <LeadVisibleAction
                  visibleCardActions={visibleCardActions}
                  availableCardActions={availableCardActions}
                />
              </div>
            </div>
          </div>
          <SidePanel
            state={toggleLeadIdentifier}
            style={{
              position: "absolute",
              width: "21%",
              boxShadow: "-4px 0px 8px 0px #00000005",
            }}
          >
            <LeadIdentifierForm
              setState={setToggleLeadIdentifier}
              onSubmitLeadIdentifierForm={onSubmitLeadIdentifierForm}
              availableFields={leadCard.leadFields}
            ></LeadIdentifierForm>
          </SidePanel>
          {isLeadIdentifierSaved && (
            <SidePanel
              direction={"left"}
              state={isLeadIdentifierSaved}
              style={{
                position: "absolute",
                width: "21%",
                left: "7rem",
                boxShadow: "-4px 0px 8px 0px #00000005",
                transform: "none",
              }}
            >
              <LeadCardConfigurationPanel
                control={control}
                setValue={setValue}
                visibleCardFields={visibleCardFields}
                availableCardFields={availableCardFields}
                visibleCardActions={visibleCardActions}
                availableCardActions={availableCardActions}
                searchedAvailableFields={searchedAvailableFields}
                searchState={searchState}
              ></LeadCardConfigurationPanel>
            </SidePanel>
          )}
        </DragDropContext>
      ) : (
        <div className={`${styles["lead-list-card"]}`}>
          <div className={`flex gap-1 align-items-center`}>
            <Avatar
              label={identifier.label}
              size="small"
              style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
              shape="circle"
            />
            <Text type="H2B">{identifier.name}</Text>
          </div>
          {isFieldsEnabledInLeadList && (
            <div className={`flex flex-column`}>
              {visibleCardFields.fields
                .filter((field) => field.view === leadControlsConstants.FILLED)
                ?.map((field) => {
                  return (
                    <Text type="T4" className="ellipsis" key={field.key}>
                      {field.displayName}
                    </Text>
                  );
                })}
            </div>
          )}
          <hr className="horizontal-divider" />
          {isActionsEnabledInLeadList && (
            <div className={`flex flex-wrap`}>
              {visibleCardActions.fields
                .filter((field) => field.view === leadControlsConstants.FILLED)
                ?.map((action) => {
                  return (
                    <img
                      alt={action.name}
                      src={`/images/launcherIcons/${action.imgName}.svg`}
                      className={`w-full h-2rem p-1 ${styles["lead-actions-icon"]}`}
                      style={{ flexBasis: "25%" }}
                      key={action.key}
                    />
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LeadCard;
