import React from "react";
import ControlledMultiselect from "../../components/LSQMultiSelect/ControlledMultiselect";
import { widgetConstants } from "../../constants/globalConstant";
import { listOptionTemplate, valueTemplate } from "../../utils/dropdownUtils";
import { useTranslation } from "react-i18next";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import useWidgetEssentials from "../../hooks/useWidgetEssentials";

const ObjectType = ({
  control,
  errors,
  setValue,
  isSingleSelect,
  isMandatory = true,
  fieldName = "objectTypes",
  onChange,
  id = "lead-field-options",
  showClear
}) => {
  const { t } = useTranslation();
  const { leadTypes, leadTypesEnabled } = useWidgetEssentials();
  const Component = isSingleSelect ? CustomDropdown : ControlledMultiselect;
  
  if (leadTypesEnabled)
    return (
      <Component
        label={t(widgetConstants.SELECT_OBJECT_TYPE)}
        isMandatory={isMandatory}
        errors={errors}
        className="w-full text-base lsq-input"
        fieldName={fieldName}
        control={control}
        options={leadTypes}
        optionLabel="Name"
        placeholder="Select"
        itemTemplate={(option, props) =>
          listOptionTemplate(option, props, "Name")
        }
        valueTemplate={(option, props) => valueTemplate(option, props, "Name")}
        panelClassName="lsq-multiselect-panel"
        maxSelectedLabels={-1}
        id={id}
        onChange={(e) => {
          setValue(fieldName, e.target.value);
          onChange();
        }}
        showClear={showClear}
      />
    );
  return <></>;
};

export default ObjectType;
