import { axiosInstanceWeb, axiosInstanceWebV2 } from "./web.axios.instance";
//"8040296d-f520-4de3-bdc0-6281196c12da"
const dashboardId = () => {
  const dashboardInfo = localStorage.getItem("currentDashboardInfo");
  if (dashboardInfo) {
    try {
      const parsedInfo = JSON.parse(dashboardInfo);
      return parsedInfo.id || "default-dashboard-id"; // Provide a default ID if none is found
    } catch (error) {
      console.error(
        "Failed to parse dashboard info from local storage:",
        error
      );
      return "default-dashboard-id"; // Provide a default ID if parsing fails
    }
  }
  return "default-dashboard-id"; // Provide a default ID if local storage is empty
};
export const GET_DATA_FROM_API_FOR_CASA_WEB = async (metadataLink) => {
  return await axiosInstanceWeb.get(metadataLink).catch((err) => err);
};

export const API_GET_WEB_WIDGET_TEMPLATE = async () => {
  return await axiosInstanceWeb
    .get(`/widget-templates`)
    .catch((err) => err.response);
};

export const API_ADD_WEB_HOMEPAGE = async () => {
  return await axiosInstanceWeb
    .post("/dashboards")
    .catch((err) => err.response);
};

export const API_UPDATE_WEB_HOMEPAGE = async (dashboardIdParam, postBody) => {
  const id = dashboardIdParam ? dashboardIdParam : dashboardId();

  return await axiosInstanceWeb
    .put(`/dashboards/${id}`, postBody)
    .catch((err) => err.response);
};

export const API_EDIT_WEB_HOMEPAGE = async (id, postBody) => {
  return await axiosInstanceWeb
    .put(`/dashboards/${id}`, postBody)
    .catch((err) => err.response);
};

export const API_GET_WEB_DASHBOARD = async (defaultDashboardId) => {
  const currentDashBoardId = defaultDashboardId || dashboardId();
  return await axiosInstanceWeb
    .get(`/dashboards/${currentDashBoardId}`)
    .catch((err) => err.response);
};

export const API_POST_CLONE_WEB_HOMEPAGE = async (id, params = {}) => {
  return await axiosInstanceWeb
    .post(`/dashboards/${id}/clone`, {}, { params })
    .catch((err) => err.response);
};

export const API_UPDATE_WEB_TEAM_DASHBOARDS = async (teamId, postBody) => {
  return await axiosInstanceWeb
    .put(`/dashboard-teams/${teamId}/order`, postBody)
    .catch((err) => err.response);
};

export const API_GET_ALL_WEB_DASHBOARDS = async () => {
  return await axiosInstanceWeb.get(`/dashboards`).catch((err) => err.response);
};

export const API_GET_WEB_TEAMS_DASHBOARDS = async () => {
  return await axiosInstanceWeb
    .get(`/dashboard-teams`)
    .catch((err) => err.response);
};

export const API_GET_WEB_TEAMS_DASHBOARDS_V2 = async () => {
  return await axiosInstanceWebV2.get(`/dashboard-teams`).catch((err) => err.response);
};

export const API_DELETE_WEB_TEAM = async (teamId) => {
  return await axiosInstanceWeb.delete(`/teams/${teamId}`).catch((err) => err.response);
};

export const API_DELETE_WEB_DASHBOARD = async (defaultDashboardId) => {
  const currentDashBoardID = defaultDashboardId || dashboardId();
  return await axiosInstanceWeb
    .delete(`/dashboards/${currentDashBoardID}`)
    .catch((err) => err.response);
};

export const API_DELETE_CLONE_WEB_DASHBOARD = async (defaultDashboardId) => {
  const currentDashBoardID = defaultDashboardId || dashboardId();
  return await axiosInstanceWeb
    .delete(`/dashboards/${currentDashBoardID}/draft`)
    .catch((err) => err.response);
};

export const API_BULK_DELETE_WEB_DASHBOARDS = async (dashboardIds) => {
  return await axiosInstanceWeb
    .delete(`/dashboards`, { data: { dashboardIds } })
    .catch((err) => err.response);
};

export const API_ADD_WEB_WIDGET = async (dashboardIdParam, postBody) => {
  const id = dashboardIdParam ? dashboardIdParam : dashboardId();

  return await axiosInstanceWeb
    .post(`/dashboards/${id}/widgets`, postBody)
    .catch((err) => err.response);
};

export const API_UPDATE_WEB_WIDGET = async (
  widgetId,
  postBody,
  dashboardIdParam
) => {
  const id = dashboardIdParam ? dashboardIdParam : dashboardId();
  return await axiosInstanceWeb
    .put(`/widgets/${widgetId}?dashboardId=${id}`, { config: postBody })
    .catch((err) => err.response);
};

export const API_DELETE_WEB_WIDGET = async (dashboardIdParam, widgetId) => {
  const id = dashboardIdParam ? dashboardIdParam : dashboardId();
  return await axiosInstanceWeb
    .delete(`/widgets/${widgetId}?dashboardId=${id}`)
    .catch((err) => err.response);
};

export const API_PUBLISH_WEB_DASHBOARD = async (dashboardId1, postBody) => {
  return await axiosInstanceWeb
    .post(`/dashboards/${dashboardId1 || dashboardId()}/publish`, postBody)
    .catch((err) => err.response);
};

export const API_CREATE_SIERA_WEB_HOMEPAGE = async (postBody) => {
  return await axiosInstanceWeb
    .post(`/dashboards/custom`, postBody)
    .catch((err) => err.response);
};

export const API_PUBLISH_CLONE_WEB_DASHBOARD = async (
  dashboardId,
  postBody
) => {
  return await axiosInstanceWeb
    .post(`/dashboards/${dashboardId || dashboardId()}/republish`, postBody)
    .catch((err) => err.response);
};

export const API_UNPUBLISH_WEB_DASHBOARD = async (defaultDashboardId) => {
  const currentDashBoardID = defaultDashboardId || dashboardId();
  return await axiosInstanceWeb
    .post(`/dashboards/${currentDashBoardID}/unpublish`)
    .catch((err) => err.response);
};

export const API_UPDATE_DASHBOARD_TEAM_ASSIGNMENT = async (
  dashboardId1,
  postBody
) => {
  return await axiosInstanceWeb
    .put(`/dashboards/${dashboardId1 || dashboardId()}/teams`, postBody)
    .catch((err) => err.response);
};

export const API_GET_WEB_HOMEPAGE_SIGNED_URL = async (key) => {
  return await axiosInstanceWeb.get(`/widget/signedUrl/${key}`);
};

export const API_GET_WEB_HOMEPAGE_TASK_COUNT = async (postBody) => {
  return await axiosInstanceWeb
    .post(`/tasks/count`, postBody)
    .catch((err) => err.response);
};
export const API_GET_WEB_UPLOAD_URL = async (featureName) => {
  const url = "/widgets/uploadUrl?" + `featureName=${featureName}`;
  return await axiosInstanceWeb.get(url);
};
