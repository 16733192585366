import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Joyride from "react-joyride";
import CoachTooltip from "../../components/Coach/CoachTooltip";
import { useNavigate } from "react-router-dom";
import RightPanel from "../../components/RightPanel";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import { ReactComponent as SaveIcon } from "../../assets/svgIcons/lsq_save.svg";
import { ReactComponent as SavedIcon } from "../../assets/svgIcons/lsq_ok.svg";
import Scaffold from "../../components/Scaffold";
import { ReactComponent as DropdownIcon } from "../../assets/svgIcons/lsq_chevron_down_filled.svg";
import LsqProgressSpinner from "../..//components/LsqProgressSpinner";
import ThemeConfigurator from "./ThemeConfigurator";
import { themeConfiguratorTabs } from "../../utils/homepageUtils";
import CustomSplitButton from "./../../components/CustomSplitButton/index";
import MobileDynamicFormThemePreview from "./MobileDynamicFormThemePreview";
import MobileSideNavigationThemePreview from "./MobileSideNavigationThemePreview";
import { lsqColorPalette, lsqPreDefinedThemes } from "../../utils/themeUtils";
import {
  ROUTES_PATH,
  globalConstants,
  themeConstants,
} from "../../constants/globalConstant";
import ToastMessage from "../../components/ToastMessage";
import styles from "./styles.module.css";
import InfoBanner from "../../components/InfoBanner";
import { apiUpdateUserPreferences } from "../../reducers/homePageSlice";
import { themeBuilderSteps } from "../../components/Coach/coachSteps";
import { useForm } from "react-hook-form";
import {
  getDefalutThemeValues,
  getIsPreviewPaneDirty,
} from "./helper/helper.js";
import { useTranslation } from "react-i18next";
import {
  createThemeByAPI,
  deleteThemeById,
  editThemeByAPI,
} from "../../reducers/themeBuilderSlice";
import CustomButton from "../../components/CustomButton";
import MessagingFramework from "../../components/MessagingFramework/index.js";

export default function ThemeBuilder() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(themeConfiguratorTabs[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    theme: currentTheme,
    toastMessages,
    deleteTheme,
    createTheme,
    editTheme,
  } = useSelector((state) => state.themeBuilder);
  const isPublished =
    currentTheme?.response &&
    Array.isArray(currentTheme.response) &&
    currentTheme.response?.[0];
  const [colorPalette, setColorPalette] = useState(
    currentTheme.response?.[0] || lsqPreDefinedThemes[0]
  );
  const themeColorPalette = lsqColorPalette(
    colorPalette.primaryColour,
    colorPalette.secondaryColour,
    colorPalette.neutralColour
  );
  const theme = useForm({
    defaultValues: { ...getDefalutThemeValues(currentTheme) },
  });

  const [themeInfoBanner, setThemeInfoBanner] = useState(true);
  const { userPreferences } = useSelector((state) => state.homepage);
  const { themebuilderVisited } = userPreferences;

  const onThemeBannerTrialClick = () => {
    navigate(ROUTES_PATH.themebuilder);
    setThemeInfoBanner(false);
  };

  const onCoachSkippedOrFinished = () => {
    const payload = {
      themebuilderVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };
  const getPublishButtonLabel = () => {
    if (isPublished) return "Published";
    return "Publish";
  };
  const getPublishAdditionalOptions = () => {
    const options = isPublished
      ? [
          {
            label: "Unpublish",
            command: () => {
              dispatch(deleteThemeById(currentTheme.response?.[0]?.themeId));
            },
          },
        ]
      : [];
    return options;
  };

  const getPublishButtonIcon = () => {
    if (
      currentTheme.responseStatus === globalConstants.PENDING ||
      createTheme.responseStatus === globalConstants.PENDING ||
      deleteTheme.responseStatus === globalConstants.PENDING
    )
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (!isPublished) return <PublishIcon />;
    return <SavedIcon fill="white" />;
  };
  const isPreviewPaneDirty = getIsPreviewPaneDirty(colorPalette, currentTheme);
  const isSaveLoading = editTheme.responseStatus === globalConstants.PENDING;
  const getSaveButtonLabel = () => {
    if (isSaveLoading) return "Saving..";
    if (isPreviewPaneDirty) return "Save";
    return "Saved";
  };
  const getSaveButtonIcon = () => {
    if (isSaveLoading)
      return <LsqProgressSpinner strokeWidth={2} strokeColor="#FFFFFF" />;
    if (isPreviewPaneDirty) return <SaveIcon fill="white" />;
    return <SavedIcon fill="white" />;
  };
  const onSaveClicked = () => {
    dispatch(
      editThemeByAPI({
        id: currentTheme.response?.[0]?.themeId,
        data: colorPalette,
      })
    );
  };

  return (
    <div
      className="flex h-screen w-screen flex-row pl-7"
      data-testid="theme-builder-view"
    >
      {!themeInfoBanner && (
        <Joyride
          tooltipComponent={(props) => (
            <CoachTooltip
              {...props}
              onCoachSkippedOrFinished={onCoachSkippedOrFinished}
            />
          )}
          continuous
          run={!themebuilderVisited}
          showProgress
          showSkipButton
          steps={themeBuilderSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
        />
      )}
      <div className={`flex lg:w-9 sm:w-8`}>
        <Scaffold
          hasBackButton={false}
          rightActionButtons={
            <div className="flex gap-1">
              <CustomButton
                disabled={!isPreviewPaneDirty}
                varient="outline"
                onClick={onSaveClicked}
                icon={getSaveButtonIcon()}
                label={t(getSaveButtonLabel())}
                data-testid="save-btn"
              />
              <CustomSplitButton
                varient="filled"
                onClick={() => {
                  isPublished || dispatch(createThemeByAPI(colorPalette));
                }}
                icon={getPublishButtonIcon()}
                label={t(getPublishButtonLabel())}
                id="publish-btn"
                additionalOptions={getPublishAdditionalOptions()}
                dropdownIcon={<DropdownIcon></DropdownIcon>}
                data-testid="theme-publish-btn"
              />
            </div>
          }
          title={themeConstants.THEMING}
        >
          <div className="flex w-full justify-content-center relative align-items-center flex-column">
            <div className="flex gap-2 coach-theme-preview ">
              <MobileSideNavigationThemePreview
                colorPalette={themeColorPalette}
              />
              <MobileDynamicFormThemePreview colorPalette={themeColorPalette} />
            </div>
            <MessagingFramework
              type={"tips"}
              message={themeConstants.INFO_1}
            />
            {toastMessages.length > 0 && (
              <div className={`${styles["toast_container"]}`}>
                <ToastMessage toastMessages={toastMessages} />
              </div>
            )}
          </div>
        </Scaffold>
      </div>
      <div className="flex lg:w-3 sm:w-4">
        <RightPanel>
          <ThemeConfigurator
            theme={theme}
            key="ThemeConfigurator"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setColorPalette={setColorPalette}
          ></ThemeConfigurator>
        </RightPanel>
      </div>
      {!themebuilderVisited && (
        <InfoBanner
          state={themeInfoBanner}
          bannerImage={`/images/theme_builder.png`}
          bannerTitle={themeConstants.THEMING}
          bannerInfo={themeConstants.THEME_BANNER_INFO}
          actionButtonLabel={themeConstants.TRY_NOW}
          hasDismissButton={false}
          actionButtonOnClick={onThemeBannerTrialClick}
        ></InfoBanner>
      )}
    </div>
  );
}
