import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { Slider } from 'primereact/slider';
import { ReactComponent as ChangeImg } from "../../../../assets/svgIcons/change_image.svg";
import { ReactComponent as Rotate } from "../../../../assets/svgIcons/rotate.svg";
import { ReactComponent as Undo } from "../../../../assets/svgIcons/lsq_undo.svg";
import Text from '../../../../components/Text';
import CustomButton from '../../../../components/CustomButton';
import styles from "./style.module.css";
import { casaWebConstants } from '../../../../constants/globalConstant';
import { useSelector } from 'react-redux';
import { countLimit } from '../../../../utils/globalUtils';
import { formatImageFile } from '../../../../utils/leadControlsUtils';
import { FileUpload } from 'primereact/fileupload';
import getCroppedImg from '../../../../components/ImageEdit/getCroppedImg';


export function ImageCropper({ setShowCropper, setShowErrorMessage, setSelectedFile, image, onCancel, onCropComplete }) {
  const { widgetType } = useSelector(state => state.webHomepage.selectedWidgetLanguage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [originalState] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0
  });


  const fileRef = useRef();
  const maxFileSize = countLimit.WEB_BANNER_MAX_FILE_SIZE;

  const handleImageSelect = (event) => {
    const imgFile = formatImageFile(event);
    setSelectedFile(imgFile);
    setShowCropper(true);
  };


  const onCropCompleteCallback = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const aspectRatio = {
    "banner": 16 / 9,
    "Cover": 817 / 128
  }

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };


  const onUndo = () => {
    setCrop(originalState.crop);
    setZoom(originalState.zoom);
    setRotation(originalState.rotation);
  };

  return (
    <div className={`flex flex-column ${styles.imageCropperWrapper}`}>
      <div className="relative w-full" style={{ height: '150px ' }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspectRatio[widgetType]}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteCallback}
          onZoomChange={setZoom}
        />
      </div>

      <div className="flex align-items-center mt-3">
        <div className="flex flex-row align-items-center gap-1 flex-grow-1">
          <Text color="#283A5A" type="T4">Zoom</Text>
          <Slider
            className={`lsq-slider ${styles.sliderWrapper}`}
            value={zoom}
            onChange={(e) => setZoom(e.value)}
            step={0.1}
            min={1}
            max={3}
          />
          <Text color="#768196" type="T4">{Math.round(100 * zoom) - 100}%</Text>
        </div>

        <div className="flex align-items-center ml-2">
          <div
            className="cursor-pointer"
            onClick={() => setRotation((prev) => (prev + 90) % 360)}
          >
            <Rotate />
          </div>

          <div>
            <ChangeImg
              data-testid="banner-change-image"
              onClick={() => {
                fileRef.current.choose();
              }}
              className="cursor-pointer"
            />
          </div>


          <div>
            <Undo onClick={onUndo} className="cursor-pointer" />
          </div>

          <FileUpload
              style={{ display: "none" }}
              className="w-5"
              mode="basic"
              name="banner-image"
              accept="image/jpeg,image/png,image/jpg,image/gif"
              maxFileSize={maxFileSize}
              chooseLabel="Add"
              customUpload={true}
              auto
              uploadHandler={(e) => {
                handleImageSelect(e);
                setShowErrorMessage(false);
              }}
              onValidationFail={() => setShowErrorMessage(true)}
              ref={fileRef}
            />

        </div>
      </div>

      <div className={`flex justify-content-end gap-2 mt-3 ${styles.buttonsWrapper}`}>
        <CustomButton
          varient="outline"
          label={casaWebConstants.CANCEL}
          onClick={onCancel}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}