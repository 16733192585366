import { casaConstants, globalConstants } from "../../constants/globalConstant";
import { ReactComponent as Url } from "../../assets/svgIcons/url.svg"
import styles from "./styles.module.css";
import CustomButton from "../CustomButton";
import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";

export default function SieraWebHomepage({
    state,
    teamId,
    onCancelClick,
    onSaveClick,
    isEdit = false,
    currentItem = { url: '', name: '',},
}) {
  const {handleSubmit, control, formState: { errors } } = useForm({ defaultValues: currentItem });
    if (state) {
        document.body.classList.add(`${styles["active-modal"]}`);
    } else {
        document.body.classList.remove(`${styles["active-modal"]}`);
    }

    const onSubmitSieraWebHomepage = (data) => {
      onSaveClick({...data, teamId });
    }
      return (
        state ? (
          <form onSubmit={handleSubmit(onSubmitSieraWebHomepage)}>
          <div className="flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-5">
        <div
          className={`flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 ${styles["modal-background"]}`}
        >
            <div className={`absolute flex flex-column gap-3 ${styles["siera-web-homepage"]}`}>
              <div>
                {!isEdit && <><div className="siera-header flex align-items-center justify-content-start font-bold text-m gap-1">
                      <Url />
                      <span>{casaConstants.SIERA_HEADING}</span>
                    </div>
                    <div className="font-normal text-m">{casaConstants.SIERA_CASA_INFO}</div></> }
                { isEdit && 
                  <div className="siera-header flex align-items-center justify-content-start font-bold text-m gap-1">
                      <span>{casaConstants.SIERA_EDIT_HEADING}</span>
                  </div>
                }
                </div>
                <CustomInput
                  errors={errors}
                  fieldName="url"
                  control={control}
                  isMandatory
                  placeholder={casaConstants.CREATE_SIERA_HOMEPAGE}
                  label="URL"
                  pattern={{
                    value: /^(?!http:)\w+:.+$/,
                    message: "Please enter a secured URL",
                  }}
                />
                <CustomInput
                  errors={errors}
                  fieldName="name"
                  control={control}
                  isMandatory
                  placeholder={casaConstants.INPUT_HOMEPAGE_TITLE}
                  label="Title"
                />
                <div className="flex gap-2">
                <CustomButton
                    varient="outline"
                    label={casaConstants.CANCEL}
                    data-testid="cancel-btn"
                    onClick={onCancelClick}
                    style={{ cursor: "pointer", fill: "#314361", width: "50%" }}
                ></CustomButton>
                <CustomButton
                    type="submit"
                    varient={`capitalize filled`}
                    data-testid="confirm-btn"
                    label={globalConstants.SAVE}
                    style={{ cursor: "pointer", fill: "#314361", width: "50%" }}
                ></CustomButton>
                </div>
            </div>
            
        </div>
      </div>
      </form>
    ) : <></>
  );
}