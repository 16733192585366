import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import { ReactComponent as DragIndicator } from "../../assets/svgIcons/drag_indicator.svg";
import PropTypes from "prop-types";
import { ReactComponent as Delete } from "../../assets/svgIcons/lsq_delete.svg";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";

export default function LsqExpandableTile({
  label,
  expanded = false,
  hasTileImage = false,
  disabled = false,
  tileImage = <></>,
  dragHandleProps,
  onDelete = () => {},
  onEdit = () => {},
  showEdit = true,
  children,
  ...props
}) {
  return (
    <div
      className={`flex flex-column w-full ${styles["item-tile"]} 
      ${disabled && "pointer-events-none"}`}
      data-testid="lsq-item-tile"
      {...props}
    >
      <div
        className={`flex align-items-center p-2 justify-content-between w-full gap-1 `}
      >
        <div className="flex justify-content-center align-items-center gap-1 overflow-hidden">
          <div className="flex align-items-center gap-1 overflow-hidden">
            <div
              className="flex justify-content-center align-items-center"
              {...dragHandleProps}
            >
              <DragIndicator />
            </div>
            {!expanded && (
              <>
                {hasTileImage && tileImage}
                <Text
                  type="sub-heading"
                  color={disabled ? "var(--N60)" : "var(--icon-primary)"}
                  className="ellipsis"
                >
                  {label}
                </Text>
              </>
            )}
          </div>
        </div>
        <div
          className={`flex gap-1 justify-content-center align-items-center ${styles["tile-actions"]}`}
        >
          {showEdit && (
            <Edit
              fill="#303DB1"
              style={{ width: "1.3rem", cursor: "pointer" }}
              onClick={() => onEdit()}
              data-testid="edit-btn"
            />
          )}
          <Delete
            fill="var(--R700)"
            style={{ width: "1.3rem", cursor: "pointer" }}
            onClick={onDelete}
            data-testid="delete-btn"
          />
        </div>
      </div>
      <div
        className={
          !expanded
            ? `${styles["collapsed-view"]}`
            : `${styles["expanded-view"]}`
        }
      >
        <div className="flex flex-column p-2 ">{children}</div>
      </div>
    </div>
  );
}

LsqExpandableTile.propTypes = {
  label: PropTypes.string,
  tileImageName: PropTypes.string,
  hasTileImage: PropTypes.bool,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};
